import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import {
  BackendGiveawayParticipationAnsweredQuestion,
  toGiveawayParticipationAnsweredQuestion,
} from '@/staff/secondary/club/giveaway/BackendGiveawayParticipationAnsweredQuestion';

export interface BackendGiveawayParticipation {
  id: string;
  giveawayId: string;
  clubSlug: string;
  email: string;
  numberOfRightAnswers: number;
  createdAt: string;
  answeredQuestions: BackendGiveawayParticipationAnsweredQuestion[];
}

export const toGiveawayParticipation = (participation: BackendGiveawayParticipation): GiveawayParticipation => ({
  id: participation.id,
  giveawayId: participation.giveawayId,
  clubSlug: participation.clubSlug,
  email: participation.email,
  numberOfRightAnswers: participation.numberOfRightAnswers,
  date: new Date(participation.createdAt),
  answeredQuestions: participation.answeredQuestions.map(toGiveawayParticipationAnsweredQuestion),
});
