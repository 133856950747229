import { Currency } from '@/common/domain/token/Currency';

export class Fiat {
  constructor(
    public readonly value: number,
    public readonly currency: Currency
  ) {}

  static euro(value: number): Fiat {
    return new Fiat(value, Currency.EURO);
  }

  plus(fiat: Fiat): Fiat {
    return new Fiat(this.value + fiat.value, this.currency);
  }
}
