import { useRouter } from 'vue-router';
import { inject, onMounted, ref } from 'vue';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { Loader } from '@/common/primary/loader/Loader';
import { ClubSummaryUi, fromClubSummary } from '@/staff/primary/club/ClubSummary.ui';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubSummary } from '@/staff/domain/club/ClubSummary';
import { loggerKey } from '@/common/domain/Logger';

export default {
  name: 'ClubSelection',

  setup() {
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const selectedClubSlug = ref('');
    const clubSummaries = ref(Loader.loading<ClubSummaryUi[]>());

    const redirectToClub = () => (slug: ClubSlug) => router.replace({ name: 'clubOverview', params: { clubSlug: slug } });

    const loadClubSummaries = () => async () => {
      let clubSummariesResponse: ClubSummary[] = [];
      try {
        clubSummariesResponse = await clubRepository.list();
      } catch (error: any) {
        logger.error('Failed to retrieve club list', error);
      } finally {
        clubSummaries.value.loaded(clubSummariesResponse.map(fromClubSummary));
      }
    };

    onMounted(async () => {
      clubRepository.findSelectedSlug().ifPresentOrElse(redirectToClub(), loadClubSummaries());
    });

    return {
      clubSummaries,
      selectedClubSlug,
    };
  },
};
