import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { fromGiveawayQuestion, GiveawayQuestionUi } from '@/staff/primary/giveaway/giveaways-table/GiveawayQuestion.ui';
import { fromPartner, PartnerUi } from '@/staff/primary/giveaway/giveaways-table/Partner.ui';
import { DateUi } from '@/common/primary/date/Date.ui';
import { GiveawayStatus } from '@/staff/domain/club/giveaway/GiveawayStatus';

export const SCHEDULED_TAG_CLASS = '-info';
export const ACTIVE_TAG_CLASS = '-success';
export const TO_CLOSE_TAG_CLASS = '-danger';
export const CLOSED_TAG_CLASS = '';

export type TagClass = typeof ACTIVE_TAG_CLASS | typeof CLOSED_TAG_CLASS | typeof SCHEDULED_TAG_CLASS | typeof TO_CLOSE_TAG_CLASS;

export interface GiveawayUi {
  id: string;
  url: string;
  startDate: DateUi;
  endDate: DateUi;
  status: GiveawayStatus;
  numberOfParticipations: number;
  tagClass: TagClass;
  questions: GiveawayQuestionUi[];
  partner?: PartnerUi;
}

const tagByStatus: Record<GiveawayStatus, TagClass> = {
  [GiveawayStatus.SCHEDULED]: SCHEDULED_TAG_CLASS,
  [GiveawayStatus.ACTIVE]: ACTIVE_TAG_CLASS,
  [GiveawayStatus.TO_CLOSE]: TO_CLOSE_TAG_CLASS,
  [GiveawayStatus.CLOSED]: CLOSED_TAG_CLASS,
};

export const fromGiveaway = (giveaway: Giveaway): GiveawayUi => ({
  id: giveaway.id,
  url: giveaway.url,
  startDate: new DateUi(giveaway.startDate),
  endDate: new DateUi(giveaway.endDate),
  status: giveaway.status,
  numberOfParticipations: giveaway.numberOfParticipations,
  tagClass: tagByStatus[giveaway.status],
  questions: giveaway.questions.map(fromGiveawayQuestion),
  partner: giveaway.partner.map(fromPartner).orElseUndefined(),
});
