.fp-horizontal-spacing--column.-expand
  .fp-card
    .fp-vertical-spacing.-s-xs.-justify-center
      .fp-vertical-spacing--line.-align-center
        i.fp-icon.fp-glyph-user.-s24
      .fp-vertical-spacing--line.-align-center(v-if='club.referralNeeded' data-test='fans-statistics-total-members')
        .fp-text.-text-semi-bold.-text-2xl(v-if='!fansList.isLoading()') {{ fansList.value().length }}
        .fp-placeholder.-height-sm(v-else)
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-sm {{ t('home.fansStatistics.totalMembers') }}
      .fp-vertical-spacing--line.-align-center(v-else data-test='fans-statistics-total-fans')
        .fp-text.-text-semi-bold.-text-2xl(v-if='!fansStatistics.isLoading()') {{ fansStatistics.value().totalFans }}
        .fp-placeholder.-height-sm(v-else)
        .fp-vertical-spacing--line.-align-center
          .fp-text.-text-semi-bold.-text-sm {{ t('home.fansStatistics.totalFans') }}
.fp-horizontal-spacing--column.-expand(v-if='showGiveawayParticipations' data-test='fans-statistics-giveaway-participations')
  .fp-card
    .fp-vertical-spacing.-s-xs.-justify-center
      .fp-vertical-spacing--line.-align-center
        i.fp-icon.fp-glyph-award.-s24
      .fp-vertical-spacing--line.-align-center
        .fp-text.-text-semi-bold.-text-2xl(v-if='!fansStatistics.isLoading()') {{ fansStatistics.value().giveawayParticipations }}
        .fp-placeholder.-height-sm(v-else)
      .fp-vertical-spacing--line.-align-center
        .fp-text.-text-semi-bold.-text-sm {{ t('home.fansStatistics.giveawayParticipations') }}
