import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { StafferLanguageRepository } from '@/common/domain/StafferLanguageRepository';

type MessageSchemaEn = typeof en;
type MessageSchemaFr = typeof fr;

export const createFairplayerI18n = (stafferLanguageRepository: StafferLanguageRepository) => {
  return createI18n<[MessageSchemaEn & MessageSchemaFr], 'en' | 'fr'>({
    locale: stafferLanguageRepository.getCurrentLanguage(),
    legacy: false,
    messages: {
      fr,
      en,
    },
  });
};
