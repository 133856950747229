import { CoinsGiftToCreate } from '@/staff/domain/club/CoinsGiftToCreate';

export interface BackendCoinsGiftToCreate {
  fanEmail: string;
  coinsAmount: number;
}

export const toBackendCoinsGiftToCreate = (coinsGift: CoinsGiftToCreate): BackendCoinsGiftToCreate => ({
  fanEmail: coinsGift.fanEmail,
  coinsAmount: coinsGift.coinsAmount,
});
