import { ReferralToCreate } from '@/staff/domain/referral/ReferralToCreate';

export interface BackendReferralToCreate {
  fanEmail: string;
  bypassDueDiligence: boolean;
  bypassMembershipPayment: boolean;
}

export const toBackendReferralToCreate = (referral: ReferralToCreate): BackendReferralToCreate => ({
  fanEmail: referral.email,
  bypassDueDiligence: referral.bypassDueDiligence,
  bypassMembershipPayment: referral.bypassMembershipPayment,
});
