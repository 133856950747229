import { ExclusiveOrder } from '@/staff/domain/exclusive/ExclusiveOrder';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';

export interface BackendExclusiveOrder {
  id: string;
  name: string;
  slug: string;
  description: string;
  imageUrl: string;
  price: BackendPrice;
  category: ExclusiveCategory;
}

export const toExclusiveOrder = (backendExclusive: BackendExclusiveOrder): ExclusiveOrder => ({
  id: backendExclusive.id,
  name: backendExclusive.name,
  slug: backendExclusive.slug,
  category: backendExclusive.category,
  description: backendExclusive.description,
  imageUrl: backendExclusive.imageUrl,
  pricing: toTokens(backendExclusive.price),
});
