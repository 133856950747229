import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { Loader } from '@/common/primary/loader/Loader';
import router from '@/router/router';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { ExclusiveFormUi, fromExclusive, toExclusiveToUpdate } from '@/staff/primary/exclusive/exclusive-form/ExclusiveForm.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveFormVue } from '@/staff/primary/exclusive/exclusive-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { isExclusiveToUpdateEmpty } from '@/staff/domain/exclusive/ExclusiveToUpdate';

export default defineComponent({
  name: 'UpdateExclusive',

  components: {
    ExclusiveFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    exclusiveSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const club = clubRepository.getCurrentClub();
    const alertBus = inject(alertBusKey)!;
    const exclusive = ref<Exclusive>();
    const exclusiveForm = ref(Loader.loading<ExclusiveFormUi>());
    const exclusiveTokensPrice = computed(() => exclusive.value?.pricing.tokenCost.value);

    onMounted(() => {
      exclusiveRepository
        .getBySlug(props.clubSlug, props.exclusiveSlug)
        .then(retrievedExclusive => {
          setFormValue(retrievedExclusive);
        })
        .catch(error => logger.error('Failed to retrieve exclusive', error));
    });

    const onConfirmUpdate = (updatedExclusiveForm: ExclusiveFormUi): Promise<void> => {
      const exclusiveToUpdate = toExclusiveToUpdate(updatedExclusiveForm, exclusive.value!, props.clubSlug);
      if (isExclusiveToUpdateEmpty(exclusiveToUpdate)) {
        return Promise.resolve();
      }

      return exclusiveRepository
        .update(exclusiveToUpdate)
        .then(retrievedExclusive => {
          setFormValue(retrievedExclusive);
          alertBus.alert('toasts.success.exclusive.update', 'success');
          router.push({ name: 'updateExclusive', params: { clubSlug: props.clubSlug, exclusiveSlug: updatedExclusiveForm.slug } });
        })
        .catch(error => logger.error('Failed to update exclusive', error));
    };

    const setFormValue = (retrievedExclusive: Exclusive) => {
      exclusive.value = retrievedExclusive;
      exclusiveForm.value.loaded(fromExclusive(retrievedExclusive, club.languages));
    };

    const onGoBack = () => {
      router.push({ name: 'exclusives', params: { clubSlug: props.clubSlug } });
    };

    return {
      exclusiveForm,
      exclusive,
      exclusiveTokensPrice,
      onGoBack,
      onConfirmUpdate,
      t,
    };
  },
});
