import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { AuctionStatus } from '@/staff/domain/exclusive/AuctionStatus';

export interface AuctionUi {
  id: string;
  name: string;
  startDate: DateTimeInputUi;
  endDate: DateTimeInputUi;
  status: AuctionStatus;
  anonymous: boolean;
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  id: auction.id,
  name: auction.name,
  startDate: new DateTimeInputUi(auction.startDate),
  endDate: new DateTimeInputUi(auction.endDate),
  status: auction.status,
  anonymous: auction.anonymous,
});
