import { Fiat } from '@/common/domain/token/Fiat';
import { Orders } from '@/staff/domain/order/Orders';
import { Donations } from '@/staff/domain/donation/Donations';

export class GlobalStatistics {
  constructor(
    private readonly orders: Orders,
    private readonly donations: Donations
  ) {}

  salesAmount(): Fiat {
    return this.donations.total().plus(this.totalAmountFiatOrders());
  }

  private totalAmountFiatOrders(): Fiat {
    return this.orders.totalFinalCosts().totalCost;
  }
}
