include ../../club/loading-table.mixin.pug

PageVue
  .fp-vertical-spacing.-s-lg
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(@click='onGoBack' data-test='giveaway-results.back-header')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('giveawayResults.title') }}
    .fp-vertical-spacing--line
      template(v-if='giveawayForm.isLoading()')
        +loading-table
      GiveawayResultsHeaderVue(v-else :club-slug='clubSlug' :giveaway-form='giveawayForm.value()')
    .fp-vertical-spacing--line(v-if='!giveawayQuestions.isLoading() && !giveawayForm.isLoading() && giveawayForm.value().questions.length')
      GiveawayResultsStatisticsVue(:club-slug='clubSlug' :giveaway-id='giveawayId' :giveaway-questions='giveawayQuestions.value()')
    .fp-vertical-spacing--line(v-if='!giveawayForm.isLoading() && missingAnswers.value().length')
      GiveawayMissingAnswersVue(:club-slug='clubSlug' :missing-answers='missingAnswers.value()' @missing-answers-filled='(event) => updateMissingAnswers(event.value)')
    .fp-vertical-spacing--line
      template(v-if='giveawayForm.isLoading()')
        +loading-table
      GiveawayParticipationsTableVue(v-else :club-slug='clubSlug' :giveaway-id='giveawayId' :giveaway-closure='giveawayClosure.value()' @extra-gift-updated='extraGiftUpdated' @coins-amount-by-right-answer-updated='event => coinsAmountByRightAnswerUpdated(event.value)' @total-gifts-updated='totalGiftsUpdated')
  template(v-if='!giveawayClosure.isLoading() && giveawayClosure.value().closable' v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(secondary :text='t(\'giveawayResults.back\')' icon='chevron-left' @click='onGoBack' data-test='giveaway-results.back-footer')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(secondary :text='t(\'giveawayResults.closeNoGift\')' icon='close' @click='openCloseWithoutGiftConfirmation' :loading='isClosing' :disabled='missingAnswers.value().length > 0' data-test='giveaway-results.close-no-gift')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'giveawayResults.closeWithGifts\')' icon='coins' @click='openCloseWithGiftsConfirmation' :loading='isClosing' :disabled='missingAnswers.value().length > 0' data-test='giveaway-results.close-with-gifts')
