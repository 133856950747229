import { InjectionKey } from 'vue';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { PresignedUpload } from '@/staff/domain/upload/PresignedUpload';
import { FileContentType } from '@/common/domain/FileContentType';

export const uploadRepositoryKey: InjectionKey<UploadRepository> = Symbol();

export interface UploadRepository {
  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload>;
  uploadFileToStorage(file: File, presignedUrl: string): Promise<void>;
}
