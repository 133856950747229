import { FansStatistics } from '@/staff/domain/club/FansStatistics';

export interface BackendFansStatistics {
  totalFans: number;
  giveawayParticipations: number;
}

export const toFansStatistics = (backendFansStatistics: BackendFansStatistics): FansStatistics => ({
  totalFans: backendFansStatistics.totalFans,
  giveawayParticipations: backendFansStatistics.giveawayParticipations,
});
