import { Loader } from '@/common/primary/loader/Loader';
import { donationRepositoryKey } from '@/staff/domain/donation/DonationRepository';
import { DonationsUi, emptyDonations, fromDonations } from '@/staff/primary/donation/Donations.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'DonationsTable',

  setup() {
    const { t } = useI18n();
    const donationRepository = inject(donationRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const hasDonations = computed(() => !donations.value.isLoading() && donations.value.value().donations.length > 0);
    const donations = ref(Loader.loading<DonationsUi>());

    onMounted(() => listDonations());

    const listDonations = () => {
      donationRepository
        .getByClub(clubRepository.getCurrentClub().slug)
        .then(repositoryDonations => donations.value.loaded(fromDonations(repositoryDonations)))
        .catch(error => {
          donations.value.loaded(emptyDonations());
          logger.error('Failed to retrieve donations', error);
        });
    };

    return {
      hasDonations,
      donations,
      t,
    };
  },
});
