import { Fiat } from '@/common/domain/token/Fiat';
import { CoinsHolding } from '@/staff/domain/club/CoinsHolding';
import { Coins } from '@/common/domain/token/Coins';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';

export class CoinsHoldings {
  constructor(
    public readonly holdings: CoinsHolding[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    const coins = new Coins(
      this.holdings.reduce((total, coinsHolding) => coinsHolding.coins.coins.amount + total, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
