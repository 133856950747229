import { inject, onMounted, ref, useSlots } from 'vue';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { ComposerTranslation, useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { Language } from '@/common/domain/Language';
import { stafferLanguageRepositoryKey } from '@/common/domain/StafferLanguageRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { BreadcrumbVue } from '@/common/primary/breadcrumb';
import { StafferIdentity } from '@/common/domain/auth/StafferIdentity';
import { themeRepositoryKey } from '@/staff/domain/theme/ThemeRepository';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';
import { SidebarVue } from '@/common/primary/sidebar';

const computeStafferName = (staffer: StafferIdentity, t: ComposerTranslation): string => {
  if (staffer.firstName) {
    return `${staffer.firstName} ${staffer.lastName}`;
  }

  return t('administratorAccount');
};

export default {
  name: 'Page',

  components: {
    BreadcrumbVue,
    DropdownVue,
    DropdownItemVue,
    DropdownSeparatorVue,
    SidebarVue,
  },

  setup() {
    const { t } = useI18n();
    const slots = useSlots();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const stafferLanguageRepository = inject(stafferLanguageRepositoryKey)!;
    const themeRepository = inject(themeRepositoryKey)!;

    const stafferName = ref('');
    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(async () => {
      const staffer = await authentication.authenticatedStaffer();
      stafferName.value = computeStafferName(staffer, t);
    });

    const logout = () => authentication.logout();

    const setLanguage = (language: Language) => {
      stafferLanguageRepository.storeLanguage(language);
      globalWindow.location.reload();
    };

    const setTheme = (theme: string) => {
      themeRepository.save(theme);
      themeRepository.set(theme);
    };

    return { club, slots, stafferName, t, logout, setLanguage, setTheme };
  },
};
