import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { BackendGiveawayExtraGift, toGiveawayExtraGift } from '@/staff/secondary/club/giveaway/BackendGiveawayExtraGift';
import { GiveawayClosure } from '@/staff/domain/club/giveaway/GiveawayClosure';

export interface BackendGiveawayClosure {
  giftByRightAnswer: BackendPrice;
  extraGifts: BackendGiveawayExtraGift[];
}

export const toGiveawayClosure = (backendClosure: BackendGiveawayClosure): GiveawayClosure => ({
  giftByRightAnswer: toTokens(backendClosure.giftByRightAnswer),
  extraGifts: backendClosure.extraGifts.map(toGiveawayExtraGift),
});
