import { Tokens } from '@/common/domain/token/Tokens';
import { CoinsUi, emptyCoinsUi, fromCoins } from '@/common/primary/token/Coins.ui';
import { emptyFiatUi, FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';

export interface TokensUi {
  coins: CoinsUi;
  totalCost: FiatUi;
  tokenCost: FiatUi;
}

export const emptyTokensUi = (): TokensUi => ({
  coins: emptyCoinsUi(),
  totalCost: emptyFiatUi(),
  tokenCost: emptyFiatUi(),
});

export const fromTokens = (tokens: Tokens): TokensUi => ({
  coins: fromCoins(tokens.coins),
  totalCost: fromFiat(tokens.totalCost),
  tokenCost: fromFiat(tokens.tokenCost),
});
