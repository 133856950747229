import { fromGiveawayAnswer, GiveawayAnswerUi } from '@/staff/primary/giveaway/GiveawayAnswer.ui';
import { GiveawayQuestion } from '@/staff/domain/club/giveaway/GiveawayQuestion';

export interface GiveawayQuestionUi {
  text: string;
  answers: GiveawayAnswerUi[];
}

export const fromGiveawayQuestion = (giveawayQuestion: GiveawayQuestion): GiveawayQuestionUi => ({
  text: giveawayQuestion.title,
  answers: giveawayQuestion.answers.map(fromGiveawayAnswer),
});
