import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { globalWindowKey } from '@/common/domain/Window';
import { MittAlertBus } from '@/common/secondary/alert/MittAlertBus';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import ConsoleLogger from '@/common/secondary/ConsoleLogger';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { clubStatisticsRepositoryKey } from '@/staff/domain/club/statistics/ClubStatisticsRepository';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { orderRepositoryKey } from '@/staff/domain/order/OrderRepository';
import { BackendClubRepository } from '@/staff/secondary/club/BackendClubRepository';
import { FairplayerClubRepository } from '@/staff/secondary/club/FairplayerClubRepository';
import { FairplayerClubStatisticsRepository } from '@/staff/secondary/club/statistics/FairplayerClubStatisticsRepository';
import { UrlParserClubSlugRepository } from '@/staff/secondary/club/UrlParserClubSlugRepository';
import { BackendExclusiveRepository } from '@/staff/secondary/exclusive/BackendExclusiveRepository';
import { BackendOrderRepository } from '@/staff/secondary/order/BackendOrderRepository';
import axios from 'axios';
import mitt from 'mitt';
import { InjectionKey } from 'vue';
import { loggerKey } from '@/common/domain/Logger';
import { BackendStafferRepository } from '@/staff/secondary/staffer/BackendStafferRepository';
import { stafferRepositoryKey } from '@/staff/domain/staffer/StafferRepository';
import { MittClickBus } from '@/common/secondary/click/MittClickBus';
import { clickBusKey } from '@/common/domain/click/ClickBus';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { MittModalBus } from '@/common/secondary/modal/MittModalBus';
import { FairplayerStafferLanguageRepository } from '@/staff/secondary/staffer/FairplayerStafferLanguageRepository';
import { stafferLanguageRepositoryKey } from '@/common/domain/StafferLanguageRepository';
import { S3Repository } from '@/staff/secondary/upload/S3Repository';
import { BackendGiveawayParticipationRepository } from '@/staff/secondary/club/giveaway/BackendGiveawayParticipationRepository';
import { giveawayParticipationRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';
import { BackendProgramRepository } from '@/staff/secondary/program/BackendProgramRepository';
import { postRepositoryKey } from '@/staff/domain/post/PostRepository';
import { BackendPostRepository } from '@/staff/secondary/post/BackendPostRepository';
import { BackendFanRepository } from '@/staff/secondary/fan/BackendFanRepository';
import { fanRepositoryKey } from '@/staff/domain/fan/FanRepository';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { FairplayerUploadRepository } from '@/staff/secondary/upload/FairplayerUploadRepository';
import { uploadRepositoryKey } from '@/staff/domain/upload/UploadRepository';
import { PageRedirecter, pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { StorageLoginContextRepository } from '@/common/secondary/login/StorageLoginContextRepository';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { StorageThemeRepository } from '@/staff/secondary/theme/StorageThemeRepository';
import { themeRepositoryKey } from '@/staff/domain/theme/ThemeRepository';
import { BackendReferralRepository } from '@/staff/secondary/referral/BackendReferralRepository';
import { referralRepositoryKey } from '@/staff/domain/referral/ReferralRepository';
import { BackendDonationRepository } from '@/staff/secondary/donation/BackendDonationRepository';
import { donationRepositoryKey } from '@/staff/domain/donation/DonationRepository';
import { createFairplayerAuthentication } from '@/providers/FairplayerAuthentication.provider';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { MittSidebarBus } from '@/common/secondary/sidebar/MittSidebarBus';
import { BackendAuctionRepository } from '@/staff/secondary/exclusive/BackendAuctionRepository';
import { auctionRepositoryKey } from '@/staff/domain/exclusive/AuctionRepository';

export const getProvider = <T>(key: InjectionKey<T>): T => providers.get(key);

const provideKey = <T>(key: InjectionKey<T>, value: T): [InjectionKey<T>, T] => [key, value];

const consoleLogger = new ConsoleLogger(console);
const pageRedirecter = new PageRedirecter(window);
const alertBus = new MittAlertBus(mitt());
const themeRepository = new StorageThemeRepository(window.localStorage, window.document);
const authentication = await createFairplayerAuthentication(alertBus, consoleLogger);
const clickBus = new MittClickBus(mitt(), window);
const modalBus = new MittModalBus(mitt());
const sidebarBus = new MittSidebarBus(mitt());
const stafferLanguageRepository = new FairplayerStafferLanguageRepository(window.localStorage, window);
const backendCaller = new BackendCaller(
  axios.create({ baseURL: import.meta.env.VITE_FAIRPLAYER_API_URL }),
  authentication,
  alertBus,
  stafferLanguageRepository
);

const backendClubRepository = new BackendClubRepository(backendCaller);
const backendAuctionRepository = new BackendAuctionRepository(backendCaller);
const backendExclusiveRepository = new BackendExclusiveRepository(backendCaller);
const backendProgramRepository = new BackendProgramRepository(backendCaller);
const backendPostRepository = new BackendPostRepository(backendCaller);
const backendReferralRepository = new BackendReferralRepository(backendCaller);
const s3Repository = new S3Repository(axios);
const loginContextRepository = new StorageLoginContextRepository(window.sessionStorage);
const backendUploadRepository = new FairplayerUploadRepository(s3Repository, backendClubRepository);
const clubSlugRepository = new UrlParserClubSlugRepository(window);
const clubRepository = new FairplayerClubRepository(backendClubRepository, clubSlugRepository, window.localStorage);
const backendMemberRepository = new BackendFanRepository(backendCaller);
const backendOrderRepository = new BackendOrderRepository(backendCaller);
const backendDonationRepository = new BackendDonationRepository(backendCaller);
const backendGiveawayParticipationRepository = new BackendGiveawayParticipationRepository(backendCaller);
const fairplayerClubStatisticsRepository = new FairplayerClubStatisticsRepository(backendOrderRepository, backendDonationRepository);
const backendStafferRepository = new BackendStafferRepository(backendCaller);

const providers = new Map<InjectionKey<any>, any>([
  provideKey(alertBusKey, alertBus),
  provideKey(authenticationKey, authentication),
  provideKey(auctionRepositoryKey, backendAuctionRepository),
  provideKey(clickBusKey, clickBus),
  provideKey(clubRepositoryKey, clubRepository),
  provideKey(fanRepositoryKey, backendMemberRepository),
  provideKey(clubStatisticsRepositoryKey, fairplayerClubStatisticsRepository),
  provideKey(exclusiveRepositoryKey, backendExclusiveRepository),
  provideKey(uploadRepositoryKey, backendUploadRepository),
  provideKey(programRepositoryKey, backendProgramRepository),
  provideKey(postRepositoryKey, backendPostRepository),
  provideKey(globalWindowKey, window),
  provideKey(loggerKey, consoleLogger),
  provideKey(loginContextRepositoryKey, loginContextRepository),
  provideKey(modalBusKey, modalBus),
  provideKey(sidebarBusKey, sidebarBus),
  provideKey(orderRepositoryKey, backendOrderRepository),
  provideKey(donationRepositoryKey, backendDonationRepository),
  provideKey(giveawayParticipationRepositoryKey, backendGiveawayParticipationRepository),
  provideKey(pageRedirecterKey, pageRedirecter),
  provideKey(referralRepositoryKey, backendReferralRepository),
  provideKey(stafferLanguageRepositoryKey, stafferLanguageRepository),
  provideKey(stafferRepositoryKey, backendStafferRepository),
  provideKey(themeRepositoryKey, themeRepository),
]);

export const provideAll = (provide: (providerKey: InjectionKey<any>, provider: any) => void) => {
  const keys = providers.keys();
  for (let i = 0; i < providers.size; i++) {
    const next = keys.next();
    const key = next.value!;
    const value = providers.get(key);
    provide(key, value);
  }
};
