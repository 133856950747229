import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';

export interface BackendExclusiveToUpdate {
  slug?: string;
  imageUrl?: string;
  medias?: BackendMedia[];
  priceCoinsAmount?: number;
  visible?: boolean;
  stock?: number;
  category?: string;
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

const toBackendExclusiveToUpdateMedias = (medias: Optional<Media[]>): BackendMedia[] | undefined => {
  if (medias.isEmpty()) {
    return undefined;
  }
  return medias.map(mediaList => mediaList.map(toBackendMedia)).orElseUndefined();
};

export const toBackendExclusiveToUpdate = (exclusiveToUpdate: ExclusiveToUpdate): BackendExclusiveToUpdate => ({
  slug: exclusiveToUpdate.slug.orElseUndefined(),
  imageUrl: exclusiveToUpdate.imageUrl.orElseUndefined(),
  medias: toBackendExclusiveToUpdateMedias(exclusiveToUpdate.medias),
  priceCoinsAmount: exclusiveToUpdate.priceCoinsAmount.orElseUndefined(),
  visible: exclusiveToUpdate.visible.orElseUndefined(),
  stock: exclusiveToUpdate.stock.orElseUndefined(),
  category: exclusiveToUpdate.category.orElseUndefined(),
  nameTranslations: exclusiveToUpdate.nameTranslations.map(fromTranslation<ExclusiveName>),
  descriptionTranslations: exclusiveToUpdate.descriptionTranslations.map(fromTranslation<ExclusiveDescription>),
});
