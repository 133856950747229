import { Fiat } from '@/common/domain/token/Fiat';
import { Order } from '@/staff/domain/order/Order';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { Coin } from '@/common/domain/token/Coin';

export class Orders {
  constructor(
    public readonly orders: Order[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    return this.total(order => order.originalCost);
  }

  totalUsedCoins(): Tokens {
    return this.total(order => order.usedCoins);
  }

  totalFinalCosts(): Tokens {
    return this.total(order => order.finalCost);
  }

  total(orderCost: (order: Order) => Tokens): Tokens {
    return this.totalOf(orderCost, this.orders);
  }

  totalOf(orderCost: (order: Order) => Tokens, orders: Order[]): Tokens {
    const coins = new Coins(
      orders.reduce((total, order) => total + orderCost(order).coins.amount, 0),
      this.coin.ticker
    );

    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
