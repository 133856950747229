import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Program } from '@/staff/domain/program/Program';
import { Url } from '@/common/domain/Url';
import { Language } from '@/common/domain/Language';
import { ProgramToCreate } from '@/staff/domain/program/ProgramToCreate';
import { ProgramToUpdate } from '@/staff/domain/program/ProgramToUpdate';
import { Optional } from '@/common/domain/Optional';
import { emptyTranslations, fromTranslations, toTranslation, translationHasChanged, TranslationUi } from '@/common/primary/Translation.ui';
import { ProgramName } from '@/staff/domain/program/ProgramName';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export type ProgramTextFormInput = keyof Pick<ProgramFormUi, 'imageUrl'>;

export interface ProgramFormUi {
  slug: string;
  imageUrl: Url;
  nameTranslations: TranslationUi[];
  descriptionTranslations: TranslationUi[];
}

export const toProgramToCreate = (programForm: ProgramFormUi, clubSlug: ClubSlug): ProgramToCreate => ({
  clubSlug,
  slug: programForm.slug,
  imageUrl: programForm.imageUrl,
  nameTranslations: programForm.nameTranslations.map(toTranslation<ProgramName>),
  descriptionTranslations: programForm.descriptionTranslations.map(toTranslation<ProgramDescription>),
});

export const fromProgram = (program: Program, languages: Language[]): ProgramFormUi => ({
  slug: program.slug,
  imageUrl: program.imageUrl,
  nameTranslations: fromTranslations(languages, program.nameTranslations!),
  descriptionTranslations: fromTranslations(languages, program.descriptionTranslations!),
});

export const toProgramToUpdate = (programForm: ProgramFormUi, program: Program, clubSlug: ClubSlug): ProgramToUpdate => ({
  clubSlug: clubSlug,
  currentSlug: program.slug,
  slug: toString(programForm, program, 'slug'),
  imageUrl: toString(programForm, program, 'imageUrl'),
  nameTranslations: programForm.nameTranslations.filter(translationHasChanged(program.nameTranslations!)).map(toTranslation<ProgramName>),
  descriptionTranslations: programForm.descriptionTranslations
    .filter(translationHasChanged(program.descriptionTranslations!))
    .map(toTranslation<ProgramDescription>),
});

type UpdatableProgramStringField = keyof Pick<ProgramFormUi, 'slug' | 'imageUrl'>;

const toString = <T>(programForm: ProgramFormUi, program: Program, field: UpdatableProgramStringField): Optional<T> =>
  programForm[field].localeCompare(program[field]) !== 0 ? Optional.of<T>(programForm[field] as T) : Optional.empty();

export const emptyProgramForm = (languages: string[]): ProgramFormUi => ({
  slug: '',
  imageUrl: '',
  nameTranslations: emptyTranslations(languages),
  descriptionTranslations: emptyTranslations(languages),
});

export const hasProgramFormChanged = (programForm: ProgramFormUi, comparedProgram: ProgramFormUi) =>
  JSON.stringify(programForm) !== JSON.stringify(comparedProgram);
