import { CoinsHoldingUi, fromCoinsHolding } from '@/staff/primary/club/coins-holdings-table/CoinsHolding.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { CoinsHoldings } from '@/staff/domain/club/CoinsHoldings';

export interface CoinsHoldingsUi {
  coinsHoldings: CoinsHoldingUi[];
  totalTokens: TokensUi;
}

export const fromCoinsHoldings = (coinsHoldings: CoinsHoldings): CoinsHoldingsUi => ({
  coinsHoldings: coinsHoldings.holdings.map(fromCoinsHolding),
  totalTokens: fromTokens(coinsHoldings.totalTokens()),
});

export const emptyCoinsHoldings = (): CoinsHoldingsUi => ({
  coinsHoldings: [],
  totalTokens: emptyTokensUi(),
});
