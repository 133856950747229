include ../../club/loading-table.mixin.pug

template(v-if='fans.isLoading() || giveawayParticipations.isLoading()')
  +loading-table
template(v-else)
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      p(v-if='!hasKnownFans' data-test='no-fans') {{ t('fans.noKnownFans') }}
      table.fp-table(v-else data-test='known-fans-table')
        thead
          tr
            th
            th.-shrink
            th {{ t('fans.table.name') }}
            th {{ t('fans.table.status.title') }}
        tbody
          template(v-for='(fan, index) in knownFans' :key='index')
            tr(:data-test='`fan-${index}`')
              td(data-test='line-trigger')
                label.fp-table--expanded-line-trigger(:for='`fan-info-${index}`')
                .fp-icon.fp-glyph-chevron-down.-s12.-color-shade-400
              td
                .fp-image.-circled
                  FairplayerImageVue(:url='fan.pictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
              td {{ fan.fullName }}
              td
                span.fp-tag(:class='`-${fan.kycStatusColor}`' data-test='status-tag')
                  i.fp-icon.-s16(:class='`fp-glyph-${fan.kycStatusIcon}`')
                  | {{ t(`fans.table.status.${fan.kycStatus}`) }}
            tr.fp-table--expanded-line(:data-test='`fan-info-${index}`')
              td
              td(colspan=3)
                input.fp-table--expanded-line-checkbox(type='checkbox' name='expandable-table' :id='`fan-info-${index}`')
                .fp-table--expanded-line-content
                  .fp-vertical-spacing.-s-lg
                    .fp-vertical-spacing--line
                      .fp-vertical-spacing.-s-sm
                        .fp-vertical-spacing--line
                          .fp-text.-text-semi-bold {{ t('fans.table.additionalInformation') }}
                        .fp-vertical-spacing--line
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.email') }}
                            .fp-horizontal-spacing--column(data-test='email')
                              span.fp-text {{ fan.email }}
                        .fp-vertical-spacing--line(v-if='fan.companyInfo' data-test='company-info')
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.companyName') }}
                            .fp-horizontal-spacing--column
                              span.fp-text {{ fan.companyInfo.name }} ({{ fan.companyInfo.registrationNumber }})
                        .fp-vertical-spacing--line
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.referralCreationDate') }}
                            .fp-horizontal-spacing--column {{ fan.referral.creationDate.toHuman() }}
                        .fp-vertical-spacing--line
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.referralReferrer') }}
                            .fp-horizontal-spacing--column(v-if='fan.referral.referrerId' data-test='referrer') {{ referrerNameFor(fan.referral.referrerId) }}
                            .fp-horizontal-spacing--column(v-else data-test='referred-by-admin') {{ t('fans.table.referredByAdmin') }}
                        .fp-vertical-spacing--line
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.bypassDueDiligence.title') }}
                            .fp-horizontal-spacing--column(data-test='bypass-due-diligence') {{ fan.referral.bypassDueDiligence ? t('fans.table.bypassDueDiligence.yes') : t('fans.table.bypassDueDiligence.no') }}
                        .fp-vertical-spacing--line
                          .fp-horizontal-spacing.-s-sm
                            .fp-horizontal-spacing--column.-expand {{ t('fans.table.bypassMembershipPayment.title') }}
                            .fp-horizontal-spacing--column(data-test='bypass-membership-payment') {{ fan.referral.bypassMembershipPayment ? t('fans.table.bypassMembershipPayment.yes') : t('fans.table.bypassMembershipPayment.no') }}
                    .fp-vertical-spacing--line(v-if='participationFor(fan.email)')
                      .fp-vertical-spacing.-s-sm
                        .fp-vertical-spacing--line
                          .fp-text.-text-semi-bold {{ t('fans.table.dueDiligence') }}
                        .fp-vertical-spacing--line
                          GiveawayParticipationAnswersVue(:giveaway-participation='participationFor(fan.email)')
                        .fp-vertical-spacing--line(v-if='fan.kycStatus === KycStatus.DUE_DILIGENCE_FILLED')
                          FairplayerButtonVue(:text='t(\'fans.table.approveDueDiligence\')' icon='check' secondary @click='approveDueDiligence(fan, $event)' :loading='dueDiligenceApproving' data-test='approve-due-diligence')
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center.-s-sm
        .fp-horizontal-spacing--column
          i.fp-icon.fp-glyph-hourglass-low.-s18
        .fp-horizontal-spacing--column.-expand
          h3.fp-title.-color-shade-700 {{ t('fans.invited') }}
        .fp-horizontal-spacing--column
          FairplayerButtonVue(:text='t(\'fans.invite\')' icon='plus' @click='openCreateReferralModal' data-test='invite')
    .fp-vertical-spacing--line
      p(v-if='!hasInvitedFans' data-test='no-invited-fans') {{ t('fans.noInvitedFans') }}
      table.fp-table(v-else data-test='invited-fans-table')
        thead
          tr
            th {{ t('fans.table.email') }}
            th {{ t('fans.table.referralCreationDate') }}
            th {{ t('fans.table.referralReferrer') }}
            th {{ t('fans.table.bypassDueDiligence.title') }}
            th {{ t('fans.table.bypassMembershipPayment.title') }}
        tbody
          tr(v-for='(fan, index) in invitedFans' :key='index' :data-test='`referred-fan-${index}`')
            td {{ fan.email }}
            td {{ fan.referral.creationDate.toHuman() }}
            td(v-if='fan.referral.referrerId' data-test='referrer') {{ referrerNameFor(fan.referral.referrerId) }}
            td(v-else data-test='referred-by-admin') {{ t('fans.table.referredByAdmin') }}
            td(data-test='bypass-due-diligence') {{ fan.referral.bypassDueDiligence ? t('fans.table.bypassDueDiligence.yes') : t('fans.table.bypassDueDiligence.no') }}
            td(data-test='bypass-membership-payment') {{ fan.referral.bypassMembershipPayment ? t('fans.table.bypassMembershipPayment.yes') : t('fans.table.bypassMembershipPayment.no') }}
