import { PostRepository } from '@/staff/domain/post/PostRepository';
import { Post } from '@/staff/domain/post/Post';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { BackendPost, toPost } from '@/staff/secondary/post/BackendPost';
import { PostToCreate } from '@/staff/domain/post/PostToCreate';
import { toBackendPostToCreate } from '@/staff/secondary/post/BackendPostToCreate';
import { PostSlug } from '@/staff/domain/post/PostSlug';
import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { toBackendPostToUpdate } from '@/staff/secondary/post/BackendPostToUpdate';

export class BackendPostRepository implements PostRepository {
  constructor(private backendCaller: BackendCaller) {}

  list(clubSlug: ClubSlug): Promise<Post[]> {
    return this.backendCaller.get<BackendPost[]>(`api/clubs/${clubSlug}/posts`).then(posts => posts.data.map(toPost));
  }

  create(postToCreate: PostToCreate): Promise<Post> {
    return this.backendCaller
      .post<BackendPost>(`api/clubs/${postToCreate.clubSlug}/posts`, toBackendPostToCreate(postToCreate))
      .then(posts => toPost(posts.data));
  }

  getBySlug(clubSlug: ClubSlug, slug: PostSlug): Promise<Post> {
    return this.backendCaller
      .get<BackendPost>(`api/clubs/${clubSlug}/posts/${slug}`, { params: { 'with-translations': true } })
      .then(posts => toPost(posts.data));
  }

  update(postToUpdate: PostToUpdate): Promise<Post> {
    return this.backendCaller
      .patch<BackendPost>(`api/clubs/${postToUpdate.clubSlug}/posts/${postToUpdate.currentSlug}`, toBackendPostToUpdate(postToUpdate))
      .then(posts => toPost(posts.data));
  }
}
