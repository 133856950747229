import { Fan } from '@/staff/domain/fan/Fan';
import { KycStatus } from '@/staff/domain/fan/KycStatus';
import { fromReferral, ReferralUi } from '@/staff/primary/fan/Referral.ui';
import { CompanyInfoUi, fromCompanyInfo } from '@/staff/primary/fan/CompanyInfo.ui';

export interface FanUi {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  kycStatus: KycStatus;
  kycStatusIcon: string;
  kycStatusColor: string;
  referral: ReferralUi;
  companyInfo?: CompanyInfoUi;
}

const toKycStatusIcon = (status: KycStatus): string => {
  if (status === KycStatus.VALIDATED) {
    return 'circle-wavy-check';
  }
  if (status === KycStatus.DUE_DILIGENCE_FILLED) {
    return 'eye';
  }
  return 'hourglass-low';
};

const toKycStatusColor = (status: KycStatus): string => {
  if (status === KycStatus.VALIDATED) {
    return 'success';
  }
  if (status === KycStatus.DUE_DILIGENCE_FILLED) {
    return 'warning';
  }
  return 'info';
};

export const fromFan = (fan: Fan): FanUi => ({
  id: fan.id,
  pictureUrl: fan.pictureUrl,
  firstName: fan.firstName,
  lastName: fan.lastName,
  fullName: `${fan.firstName} ${fan.lastName}`,
  email: fan.email,
  kycStatus: fan.kycStatus,
  kycStatusIcon: toKycStatusIcon(fan.kycStatus),
  kycStatusColor: toKycStatusColor(fan.kycStatus),
  referral: fromReferral(fan.referral),
  companyInfo: fan.companyInfo.map(fromCompanyInfo).orElseUndefined(),
});
