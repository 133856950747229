import { BackendCaller } from '@/common/secondary/BackendCaller';
import { StafferRepository } from '@/staff/domain/staffer/StafferRepository';
import { Authorization } from '@/staff/domain/staffer/Authorization';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';

export class BackendStafferRepository implements StafferRepository {
  constructor(private backendCaller: BackendCaller) {}

  async register(username: Username): Promise<void> {
    await this.backendCaller.patch<void>(`api/staffers/${encodeURIComponent(username)}`);
  }

  getAuthorizationsFor(username: Username, clubSlug: ClubSlug): Promise<Authorization[]> {
    return this.backendCaller
      .get<Authorization[]>(`api/clubs/${clubSlug}/staffers/${encodeURIComponent(username)}/authorizations`)
      .then(({ data }) => data);
  }
}
