import { useI18n } from 'vue-i18n';
import { CoinsGiftsTableVue } from '@/staff/primary/club/coins-gifts-table';
import { CoinsHoldingsTableVue } from '@/staff/primary/club/coins-holdings-table';
import { PageVue } from '@/staff/primary/club-layout/page';

export default {
  name: 'CoinsPage',

  components: {
    PageVue,
    CoinsGiftsTableVue,
    CoinsHoldingsTableVue,
  },
  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
