import { FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';
import { GlobalStatistics } from '@/staff/domain/club/statistics/GlobalStatistics';

export interface GlobalStatisticsUi {
  salesAmount: FiatUi;
}

export const fromGlobalStatistics = (globalStatistics: GlobalStatistics): GlobalStatisticsUi => ({
  salesAmount: fromFiat(globalStatistics.salesAmount()),
});
