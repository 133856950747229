import { ClubSummary } from '@/staff/domain/club/ClubSummary';

export interface BackendClubSummary {
  name: string;
  slug: string;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
}

export const toClubSummary = (backendClub: BackendClubSummary): ClubSummary => ({
  name: backendClub.name,
  slug: backendClub.slug,
  logoUrl: backendClub.logoUrl,
  bannerUrl: backendClub.bannerUrl,
  webappUrl: backendClub.webappUrl,
});
