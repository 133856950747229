import { Member } from '@/staff/domain/fan/Member';

export interface BackendMember {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
}

export const toMember = (backendMember: BackendMember): Member => ({
  id: backendMember.id,
  pictureUrl: backendMember.pictureUrl,
  firstName: backendMember.firstName,
  lastName: backendMember.lastName,
});
