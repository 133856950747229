import { StafferIdentity } from '@/common/domain/auth/StafferIdentity';
import { InjectionKey } from 'vue';
import { AuthenticationJwtTokenRetriever } from '@/common/domain/auth/AuthenticationJwtTokenRetriever';
import { Optional } from '@/common/domain/Optional';

export const authenticationKey: InjectionKey<Authentication> = Symbol();

export interface Authentication extends AuthenticationJwtTokenRetriever {
  login(): Promise<void>;
  logout(): Promise<void>;
  optionalAuthenticatedStaffer(): Promise<Optional<StafferIdentity>>;
  authenticatedStaffer(): Promise<StafferIdentity>;
  isAuthenticated(): Promise<boolean>;
}
