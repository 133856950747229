import { ClubRepository, clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawayFormUi, emptyGiveawayForm, toGiveawayToCreate, fromGiveaway } from '@/staff/primary/giveaway/GiveawayForm.ui';
import { AlertBus, alertBusKey } from '@/common/domain/alert/AlertBus';
import { Router, useRoute, useRouter } from 'vue-router';
import { Club } from '@/staff/domain/club/Club';
import { PageVue } from '@/staff/primary/club-layout/page';
import { GiveawayFormVue } from '@/staff/primary/giveaway/giveaway-form';
import { Logger, loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/common/primary/loader/Loader';

export default defineComponent({
  name: 'CreateGiveaway',

  components: {
    FairplayerButtonVue,
    GiveawayFormVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router: Router = useRouter();

    const logger: Logger = inject(loggerKey)!;
    const alertBus: AlertBus = inject(alertBusKey)!;
    const clubRepository: ClubRepository = inject(clubRepositoryKey)!;
    const club: Club = clubRepository.getCurrentClub();
    const giveawayForm = ref(Loader.loading<GiveawayFormUi>());
    const isCloning = ref(false);

    const initGiveawayForm = () => {
      const fromQueryParameter = route.query.from;
      if (fromQueryParameter) {
        isCloning.value = true;
        const [clubSlug, giveawayId] = fromQueryParameter.toString().split('/');

        return clubRepository.getGiveaway(clubSlug, giveawayId).then(giveaway => fromGiveaway(giveaway, clubSlug, t));
      }

      return emptyGiveawayForm(club.slug, club.webappUrl, t);
    };

    onMounted(async () => {
      giveawayForm.value.loaded(await initGiveawayForm());
    });

    const onGoBack = async (): Promise<void> => {
      await router.push({ name: 'giveaways', params: { clubSlug: props.clubSlug } });
    };

    const goToGiveawaysPage = async () => {
      alertBus.alert('toasts.success.giveaway.creation', 'success');

      await router.push({ name: 'giveaways', params: { clubSlug: props.clubSlug } });
    };

    const createGiveaway = async (giveawayForm: GiveawayFormUi): Promise<void> => {
      await clubRepository
        .createGiveaway(club.slug, toGiveawayToCreate(giveawayForm))
        .then(async () => goToGiveawaysPage())
        .catch(error => logger.error('Failed to create giveaway', error));
    };

    return {
      club,
      isCloning,
      createGiveaway,
      giveawayForm,
      onGoBack,
      t,
    };
  },
});
