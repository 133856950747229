import { AnsweredQuestionResult } from '@/staff/domain/club/giveaway/AnsweredQuestionResult';
import { GiveawayParticipationAnsweredQuestion } from '@/staff/domain/club/giveaway/GiveawayParticipationAnsweredQuestion';

const RIGHT_ICON_CLASS = 'fp-glyph-check -color-success';
const WRONG_ICON_CLASS = 'fp-glyph-close -color-danger';

export interface GiveawayParticipationAnsweredQuestionUi {
  questionTitle: string;
  answerText?: string;
  iconClass?: string;
}

const fromAnsweredQuestionResult = (result: AnsweredQuestionResult) => {
  if (result === AnsweredQuestionResult.RIGHT) {
    return RIGHT_ICON_CLASS;
  }
  if (result === AnsweredQuestionResult.WRONG) {
    return WRONG_ICON_CLASS;
  }

  return undefined;
};

export const fromGiveawayParticipationAnsweredQuestion = (
  question: GiveawayParticipationAnsweredQuestion
): GiveawayParticipationAnsweredQuestionUi => ({
  questionTitle: question.questionTitle,
  answerText: question.answerText.orElseUndefined(),
  iconClass: fromAnsweredQuestionResult(question.result),
});
