import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import { BackendGiveawayParticipation, toGiveawayParticipation } from '@/staff/secondary/club/giveaway/BackendGiveawayParticipation';
import {
  BackendGiveawayQuestionStatistics,
  toGiveawayQuestionStatistics,
} from '@/staff/secondary/club/giveaway/BackendGiveawayQuestionStatistics';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';
import { GiveawayParticipationRepository } from '@/staff/domain/club/giveaway/GiveawayRepository';

export class BackendGiveawayParticipationRepository implements GiveawayParticipationRepository {
  constructor(private backendCaller: BackendCaller) {}

  listGiveawayParticipations(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayParticipation[]> {
    return this.backendCaller
      .get<BackendGiveawayParticipation[]>(`api/clubs/${clubSlug}/giveaways/${giveawayId}/participations`)
      .then(giveawayParticipations => giveawayParticipations.data.map(toGiveawayParticipation));
  }

  getQuestionsStatistics(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayQuestionStatistics[]> {
    return this.backendCaller
      .get<BackendGiveawayQuestionStatistics[]>(`api/clubs/${clubSlug}/giveaways/${giveawayId}/questions-statistics`)
      .then(questionStatistics => questionStatistics.data.map(toGiveawayQuestionStatistics));
  }
}
