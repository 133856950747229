import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { Loader } from '@/common/primary/loader/Loader';
import router from '@/router/router';
import { Program } from '@/staff/domain/program/Program';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { ProgramFormUi, fromProgram, toProgramToUpdate } from '@/staff/primary/program/program-form/ProgramForm.ui';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ProgramFormVue } from '@/staff/primary/program/program-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { isProgramToUpdateEmpty } from '@/staff/domain/program/ProgramToUpdate';

export default defineComponent({
  name: 'UpdateProgram',

  components: {
    ProgramFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    programSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const alertBus = inject(alertBusKey)!;
    const program = ref<Program>();
    const programForm = ref(Loader.loading<ProgramFormUi>());

    onMounted(() => {
      programRepository
        .getBySlug(props.clubSlug, props.programSlug)
        .then(retrievedProgram => {
          setFormValue(retrievedProgram);
        })
        .catch(error => logger.error('Failed to retrieve program', error));
    });

    const onConfirmUpdate = (updatedProgramForm: ProgramFormUi): Promise<void> => {
      const programToUpdate = toProgramToUpdate(updatedProgramForm, program.value!, props.clubSlug);
      if (isProgramToUpdateEmpty(programToUpdate)) {
        return Promise.resolve();
      }

      return programRepository
        .update(programToUpdate)
        .then(retrievedProgram => {
          setFormValue(retrievedProgram);
          alertBus.alert('toasts.success.program.update', 'success');
          router.push({ name: 'updateProgram', params: { clubSlug: props.clubSlug, programSlug: updatedProgramForm.slug } });
        })
        .catch(error => logger.error('Failed to update program', error));
    };

    const setFormValue = (retrievedProgram: Program) => {
      program.value = retrievedProgram;
      programForm.value.loaded(fromProgram(retrievedProgram, club.languages));
    };

    const onGoBack = () => {
      router.push({ name: 'programs', params: { clubSlug: props.clubSlug } });
    };

    return {
      programForm,
      program,
      onGoBack,
      onConfirmUpdate,
      t,
    };
  },
});
