.fp-media-input--media
  .fp-alert.-danger(v-if='mediaNotFound' data-test='media-input-line.error')
    i.fp-icon.fp-glyph-warning.-color-danger.-s18
    span.fp-text {{ t('mediaInput.error') }}
  template(v-else)
    .fp-image.-rounded.-lg(v-show='mediaLoaded' v-if='media.type === "IMAGE"')
      img(ref='imageRef' :src='media.url' alt='Media Image' @load='onLoadedMedia' @error='onNotFoundImage' data-test='media-input-line.image')
    .fp-video.-lg(v-show='mediaLoaded' v-if='media.type === "VIDEO"')
      video(ref='videoRef' @loadeddata='onLoadedMedia' @error='onNotFoundImage' data-test='media-input-line.video' preload='metadata')
        source(:src='`${media.url}#t=0.1`')
      .fp-video--trigger
        i.fp-icon.fp-glyph-play.-color-shade-900.-s16
    i.fp-icon.fp-glyph-loader.-spin.-s18(v-if='!mediaLoaded')
  .fp-media-input--media-actions(v-if='showActions')
    .fp-media-input--media-actions-group
      button.fp-icon.fp-glyph-chevron-up.-color-shade-900.-s12(@click='moveUp' :disabled='!mediaLoaded || isFirstMedia' :class='isFirstMedia ? "-color-shade-300" : "-pointer"' data-test='media-input-line.move-up' type='button')
      button.fp-icon.fp-glyph-chevron-down.-color-shade-900.-s12(@click='moveDown' :disabled='!mediaLoaded || isLastMedia' :class='isLastMedia ? "-color-shade-300" : "-pointer"' data-test='media-input-line.move-down' type='button')
    button.fp-icon.fp-glyph-trash.-color-shade-900.-pointer.-s16(@click='deleteMedia' :disabled='!mediaLoaded' data-test='media-input-line.delete' type='button')
