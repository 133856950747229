import { Logger } from '../domain/Logger';
import { Message } from '../domain/Message';

export default class ConsoleLogger implements Logger {
  constructor(private logger: Console) {}

  error(message: Message, error?: Error) {
    this.logger.error(`${message}\n`, error);
  }
}
