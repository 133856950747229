import { computed, defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { Loader } from '@/common/primary/loader/Loader';
import { loggerKey } from '@/common/domain/Logger';
import {
  fromGiveawayParticipation,
  GiveawayParticipationUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipation.ui';
import { GiveawayExtraGiftUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayExtraGift.ui';
import {
  fromParticipationAndExtraGift,
  GiveawayParticipationResultUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipationResult.ui';
import {
  fromParticipationsAndExtraGifts,
  GiveawayParticipationTotalResultsUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipationTotalResults.ui';
import { GiveawayClosureUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayClosure.ui';
import { giveawayParticipationRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';
import { GiveawayParticipationAnswersVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/giveaway-participation-answers';

export default defineComponent({
  name: 'GiveawayParticipationsTable',

  components: { GiveawayParticipationAnswersVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    giveawayId: {
      type: String,
      required: true,
    },
    giveawayClosure: {
      type: Object as PropType<GiveawayClosureUi>,
      required: true,
    },
  },

  emits: ['coins-amount-by-right-answer-updated', 'extra-gift-updated', 'total-gifts-updated'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const giveawayParticipationRepository = inject(giveawayParticipationRepositoryKey)!;
    const coinsAmountByRightAnswer = ref(props.giveawayClosure.giftByRightAnswer.coins.amount);

    const club = clubRepository.getCurrentClub();

    const giveawayParticipations = ref(Loader.loading<GiveawayParticipationUi[]>());

    const hasAnyQuestion = computed(
      () =>
        !giveawayParticipations.value.isLoading() &&
        giveawayParticipations.value.value().length > 0 &&
        giveawayParticipations.value.value()[0].answeredQuestions.length > 0
    );

    const extraGiftByParticipationId = ref<Record<string, number>>({});

    const closable = computed(() => props.giveawayClosure.closable);

    const giveawayParticipationResultFor = (participation: GiveawayParticipationUi): GiveawayParticipationResultUi =>
      fromParticipationAndExtraGift(
        participation,
        coinsAmountByRightAnswer.value,
        extraGiftByParticipationId.value[participation.id],
        club.coin
      );

    const giveawayParticipationResultsTotal = ref<GiveawayParticipationTotalResultsUi>(
      fromParticipationsAndExtraGifts([], 0, [], club.coin)
    );

    onMounted(() => listGiveawayParticipations());

    const listGiveawayParticipations = () => {
      giveawayParticipationRepository
        .listGiveawayParticipations(props.clubSlug, props.giveawayId)
        .then(retrievedParticipations => {
          giveawayParticipations.value.loaded(retrievedParticipations.map(fromGiveawayParticipation));

          retrievedParticipations.map(p => p.id).forEach(id => (extraGiftByParticipationId.value[id] = 0));
          props.giveawayClosure.extraGifts.forEach(
            ({ gift, participationId }) => (extraGiftByParticipationId.value[participationId] = gift.coins.amount)
          );
          computeGiveawayParticipationResultsTotal();
        })
        .catch(error => {
          giveawayParticipations.value.loaded([]);
          logger.error('Failed to retrieve giveaway participations', error);
        });
    };

    const onCoinsAmountByRightAnswerInput = () => {
      emit('coins-amount-by-right-answer-updated', { value: coinsAmountByRightAnswer.value });

      computeGiveawayParticipationResultsTotal();
    };

    const computeGiveawayParticipationResultsTotal = () => {
      const total = fromParticipationsAndExtraGifts(
        giveawayParticipations.value.value(),
        coinsAmountByRightAnswer.value,
        Object.values(extraGiftByParticipationId.value),
        club.coin
      );
      giveawayParticipationResultsTotal.value = total;

      emit('total-gifts-updated', total.totalGifts);
    };

    const onExtraGiftInput = (participation: GiveawayParticipationUi) => {
      const extraGift: GiveawayExtraGiftUi = {
        gift: giveawayParticipationResultFor(participation).extraGift,
        participationId: participation.id,
      };

      emit('extra-gift-updated', extraGift);
      computeGiveawayParticipationResultsTotal();
    };

    return {
      coinsAmountByRightAnswer,
      closable,
      extraGiftByParticipationId,
      giveawayParticipations,
      giveawayParticipationResultFor,
      giveawayParticipationResultsTotal,
      hasAnyQuestion,
      onCoinsAmountByRightAnswerInput,
      onExtraGiftInput,
      t,
    };
  },
});
