include ../../../../../common/primary/select/select.mixin.pug

.fp-card(data-test='giveaway-results-header')
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-responsive-grid.-cols4.-cols2-up-md
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.startDate') }}
            input.fp-input-text(disabled data-test='start-date' :value='giveawayForm.startDate.date')
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.startTime') }}
            input.fp-input-text(disabled data-test='start-time' :value='giveawayForm.startDate.time')
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.endDate') }}
            input.fp-input-text(disabled data-test='end-date' :value='giveawayForm.endDate.date')
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.endTime') }}
            input.fp-input-text(disabled data-test='end-time' :value='giveawayForm.endDate.time')
    .fp-vertical-spacing--line
      .fp-responsive-grid.-cols2.-cols1-up-md
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.partner.label') }}
            +fp-select
              select(disabled data-test='partner')
                option(value='partner' v-text='giveawayForm.partner.name')
        .fp-responsive-grid--column
          label.fp-field
            | {{ t('giveawayForm.url') }}
            input.fp-input-text(disabled data-test='url' :value='giveawayForm.url')
