import { QuizMode } from '@/staff/domain/club/giveaway/QuizMode';
import {
  fromGiveawayAnswersStatistics,
  GiveawayAnswersStatisticsUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-results-statistics/giveaway-results-chart/GiveawayAnswerStatistics.ui';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';

export interface GiveawayQuestionStatisticsUi {
  id: string;
  noAnswerCount: number;
  totalAnswerCount: number;
  answers: GiveawayAnswersStatisticsUi[];
  isOpenQuestion: boolean;
}

export const fromGiveawayQuestionStatistics = (giveawayQuestionStatistics: GiveawayQuestionStatistics): GiveawayQuestionStatisticsUi => ({
  id: giveawayQuestionStatistics.id,
  noAnswerCount: giveawayQuestionStatistics.noAnswerCount,
  totalAnswerCount: giveawayQuestionStatistics.totalAnswerCount,
  answers: giveawayQuestionStatistics.answers.map(fromGiveawayAnswersStatistics),
  isOpenQuestion: giveawayQuestionStatistics.quizMode === QuizMode.OPEN,
});
