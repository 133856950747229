PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column.-expand
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-newspaper.-s18
            .fp-horizontal-spacing--column
              h2.fp-title.-color-shade-700 {{ t('programsPage.title') }}
        .fp-horizontal-spacing--column
          router-link.fp-link.-no-text-decoration(:to='{ name: \'createProgram\', params: { clubSlug }}' data-test='create-program')
            FairplayerButtonVue(icon='plus' :text='t(\'programsPage.createProgram\')')
    .fp-vertical-spacing--line
      ProgramsTableVue
