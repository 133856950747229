import { AnsweredQuestionResult } from '@/staff/domain/club/giveaway/AnsweredQuestionResult';
import { GiveawayAnswersStatistics } from '@/staff/domain/club/giveaway/GiveawayAnswersStatistics';

export interface BackendGiveawayAnswersStatistics {
  id: string;
  text: string;
  result: AnsweredQuestionResult;
  count: number;
}

export const toGiveawayAnswersStatistics = (backendAnswersStatistics: BackendGiveawayAnswersStatistics): GiveawayAnswersStatistics => ({
  id: backendAnswersStatistics.id,
  text: backendAnswersStatistics.text,
  result: backendAnswersStatistics.result,
  count: backendAnswersStatistics.count,
});
