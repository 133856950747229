import { Loader } from '@/common/primary/loader/Loader';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { FansStatisticsUi, fromFansStatistics } from '@/staff/primary/club/statistics/fans-statistics/FansStatistics.ui';
import { loggerKey } from '@/common/domain/Logger';
import { fanRepositoryKey } from '@/staff/domain/fan/FanRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { fromFan, FanUi } from '@/staff/primary/fan/Fan.ui';

export default defineComponent({
  name: 'FansStatistics',

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const showGiveawayParticipations = club.coinsEnabled;
    const fansList = ref(Loader.loading<FanUi[]>());
    const fansStatistics = ref(Loader.loading<FansStatisticsUi>());

    onMounted(async () => {
      getFansStatistics();
      await listFans();
    });

    const getFansStatistics = () => {
      clubRepository
        .getFansStatistics(club.slug)
        .then(statistics => fansStatistics.value.loaded(fromFansStatistics(statistics)))
        .catch(error => {
          logger.error('Failed to retrieve fans statistics', error);
        });
    };

    const listFans = async () => {
      await fanRepository
        .list(club.slug)
        .then(fans => fansList.value.loaded(fans.map(fromFan)))
        .catch(error => {
          fansList.value.loaded([]);
          logger.error('Failed to retrieve fans', error);
        });
    };

    return {
      fansStatistics,
      showGiveawayParticipations,
      fansList,
      club,
      t,
    };
  },
});
