import { inject, onMounted } from 'vue';
import { themeRepositoryKey } from '@/staff/domain/theme/ThemeRepository';

export default {
  name: 'App',

  setup() {
    const themeRepository = inject(themeRepositoryKey)!;

    onMounted(() => {
      themeRepository.get().ifPresent(theme => themeRepository.set(theme));
    });
  },
};
