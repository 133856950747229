import { inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';

export default {
  name: 'ClubsPage',

  setup() {
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;

    onMounted(async () => {
      clubRepository.clearSelectedClub();
      await router.replace({ name: 'home' });
    });
  },
};
