import { ProgramToCreate } from '@/staff/domain/program/ProgramToCreate';
import { Url } from '@/common/domain/Url';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { ProgramName } from '@/staff/domain/program/ProgramName';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export interface BackendProgramToCreate {
  slug: string;
  imageUrl: Url;
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

export const toBackendProgramToCreate = (programToCreate: ProgramToCreate): BackendProgramToCreate => ({
  slug: programToCreate.slug,
  imageUrl: programToCreate.imageUrl,
  nameTranslations: programToCreate.nameTranslations.map(fromTranslation<ProgramName>),
  descriptionTranslations: programToCreate.descriptionTranslations.map(fromTranslation<ProgramDescription>),
});
