import { AnsweredQuestionResult } from '@/staff/domain/club/giveaway/AnsweredQuestionResult';
import { GiveawayAnswersStatistics } from '@/staff/domain/club/giveaway/GiveawayAnswersStatistics';

export enum AnswerMark {
  GREEN_CHECK_MARK = '\u{2705}',
  RED_CROSS_MARK = '\u{274C}',
  EMPTY = '',
}

const getMark = (result: AnsweredQuestionResult): AnswerMark => {
  switch (result) {
    case AnsweredQuestionResult.RIGHT:
      return AnswerMark.GREEN_CHECK_MARK;
    case AnsweredQuestionResult.WRONG:
      return AnswerMark.RED_CROSS_MARK;
    case AnsweredQuestionResult.FREE:
      return AnswerMark.EMPTY;
  }
};

export interface GiveawayAnswersStatisticsUi {
  id: string;
  text: string;
  count: number;
  mark: AnswerMark;
}

export const fromGiveawayAnswersStatistics = (giveawayAnswersStatistics: GiveawayAnswersStatistics): GiveawayAnswersStatisticsUi => ({
  id: giveawayAnswersStatistics.id,
  text: giveawayAnswersStatistics.text,
  count: giveawayAnswersStatistics.count,
  mark: getMark(giveawayAnswersStatistics.result),
});
