import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Post } from '@/staff/domain/post/Post';
import { Url } from '@/common/domain/Url';
import { Language } from '@/common/domain/Language';
import { PostToCreate } from '@/staff/domain/post/PostToCreate';
import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { emptyTranslations, fromTranslations, toTranslation, translationHasChanged, TranslationUi } from '@/common/primary/Translation.ui';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';

export type PostTextFormInput = keyof Pick<PostFormUi, 'imageUrl'>;

export interface PostFormUi {
  slug: string;
  imageUrl: Url;
  date: DateTimeInputUi;
  medias: Media[];
  nameTranslations: TranslationUi[];
  descriptionTranslations: TranslationUi[];
  programSlug?: string;
}

export const toPostToCreate = (postForm: PostFormUi, clubSlug: ClubSlug): PostToCreate => ({
  clubSlug,
  slug: postForm.slug,
  imageUrl: postForm.imageUrl,
  date: DateTimeInputUi.toDate(postForm.date),
  medias: postForm.medias,
  nameTranslations: postForm.nameTranslations.map(toTranslation<PostName>),
  descriptionTranslations: postForm.descriptionTranslations.map(toTranslation<PostDescription>),
  programSlug: Optional.ofEmpty(postForm.programSlug),
});

export const fromPost = (post: Post, languages: Language[]): PostFormUi => ({
  slug: post.slug,
  imageUrl: post.imageUrl,
  date: new DateTimeInputUi(post.date),
  medias: post.medias,
  nameTranslations: fromTranslations(languages, post.nameTranslations!),
  descriptionTranslations: fromTranslations(languages, post.descriptionTranslations!),
  programSlug: post.programSlug.orElseUndefined(),
});

export const toPostToUpdate = (postForm: PostFormUi, post: Post, clubSlug: ClubSlug): PostToUpdate => ({
  clubSlug: clubSlug,
  currentSlug: post.slug,
  slug: toString(postForm, post, 'slug'),
  imageUrl: toString(postForm, post, 'imageUrl'),
  date: toDateToUpdate(postForm.date, post.date),
  medias: toMedias(postForm, post),
  nameTranslations: postForm.nameTranslations.filter(translationHasChanged(post.nameTranslations!)).map(toTranslation<PostName>),
  descriptionTranslations: postForm.descriptionTranslations
    .filter(translationHasChanged(post.descriptionTranslations!))
    .map(toTranslation<PostDescription>),
});

type UpdatablePostStringField = keyof Pick<PostFormUi, 'slug' | 'imageUrl'>;

const toString = <T>(postForm: PostFormUi, post: Post, field: UpdatablePostStringField): Optional<T> =>
  postForm[field].localeCompare(post[field]) !== 0 ? Optional.of<T>(postForm[field] as T) : Optional.empty();

const toDateToUpdate = (formDate: DateTimeInputUi, postDate: Date): Optional<Date> =>
  Optional.of<Date>(DateTimeInputUi.toDate(formDate)).filter(date => date.getTime() !== postDate.getTime());

const toMedias = (postForm: PostFormUi, post: Post): Optional<Media[]> =>
  JSON.stringify(postForm.medias).localeCompare(JSON.stringify(post.medias)) !== 0 ? Optional.of(postForm.medias) : Optional.empty();

export const emptyPostForm = (languages: string[]): PostFormUi => ({
  slug: '',
  imageUrl: '',
  date: new DateTimeInputUi(new Date()),
  medias: [],
  nameTranslations: emptyTranslations(languages),
  descriptionTranslations: emptyTranslations(languages),
  programSlug: undefined,
});

export const hasPostFormChanged = (postForm: PostFormUi, comparedPost: PostFormUi) =>
  JSON.stringify(postForm) !== JSON.stringify(comparedPost);
