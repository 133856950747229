import { AuctionLot } from '@/staff/domain/exclusive/AuctionLot';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { AuctionUi, fromAuction } from '@/staff/primary/exclusive/Auction.ui';
import { BidUi, fromBid } from '@/staff/primary/exclusive/Bid.ui';

export interface AuctionLotUi {
  startingPrice: TokensUi;
  currentPrice: TokensUi;
  auction: AuctionUi;
  number?: number;
  bids: BidUi[];
}

export const fromAuctionLot = (auctionLot: AuctionLot): AuctionLotUi => ({
  startingPrice: fromTokens(auctionLot.startingPrice),
  currentPrice: fromTokens(auctionLot.currentPrice),
  auction: fromAuction(auctionLot.auction),
  number: auctionLot.number.orElseUndefined(),
  bids: auctionLot.bids.map(bid => fromBid(bid)),
});
