import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { AuctionRepository } from '@/staff/domain/exclusive/AuctionRepository';
import { Auction } from '@/staff/domain/exclusive/Auction';
import { BackendAuction, toAuction } from '@/staff/secondary/exclusive/BackendAuction';

export class BackendAuctionRepository implements AuctionRepository {
  constructor(private backendCaller: BackendCaller) {}

  listByClub(clubSlug: ClubSlug): Promise<Auction[]> {
    return this.backendCaller.get<BackendAuction[]>(`api/clubs/${clubSlug}/auctions`).then(auctions => auctions.data.map(toAuction));
  }
}
