import { GiveawayAnswer } from '@/staff/domain/club/giveaway/GiveawayAnswer';

export interface BackendGiveawayAnswer {
  id: string;
  text: string;
}

export const toGiveawayAnswer = (backendGiveawayAnswer: BackendGiveawayAnswer): GiveawayAnswer => ({
  id: backendGiveawayAnswer.id,
  text: backendGiveawayAnswer.text,
});
