import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { CoinsHolding } from '@/staff/domain/club/CoinsHolding';

export interface BackendCoinsHolding {
  fanEmail: string;
  coins: BackendPrice;
}

export const toCoinsHolding = (backendCoinsHolding: BackendCoinsHolding): CoinsHolding => ({
  fanEmail: backendCoinsHolding.fanEmail,
  coins: toTokens(backendCoinsHolding.coins),
});

export const sortCoinsHoldingsByFanEmail = (coinsHolding1: CoinsHolding, coinsHolding2: CoinsHolding) =>
  coinsHolding1.fanEmail.localeCompare(coinsHolding2.fanEmail);
