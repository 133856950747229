import { useI18n } from 'vue-i18n';
import { inject, PropType } from 'vue';
import { AnnouncementUi } from '@/common/primary/announcement/Announcement.ui';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';

export default {
  name: 'Announcement',

  props: {
    announcementUi: {
      type: Object as PropType<AnnouncementUi>,
      required: true,
    },
  },

  setup(props: any, { emit }: any) {
    const { t } = useI18n();
    const pageRedirecter = inject(pageRedirecterKey)!;

    const goTo = async () => {
      await pageRedirecter.navigateTo(props.announcementUi.routeLink);
    };

    const close = async () => {
      emit('close');
    };

    return {
      goTo,
      close,
      t,
    };
  },
};
