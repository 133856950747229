import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';

export interface BackendMedia {
  url: string;
  type: MediaType;
}

export const toMedia = (backendMedia: BackendMedia): Media => backendMedia;

export const toBackendMedia = (media: Media): BackendMedia => media;
