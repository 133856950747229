import { Order } from '@/staff/domain/order/Order';
import { BackendExclusiveOrder, toExclusiveOrder } from '@/staff/secondary/order/BackendExclusiveOrder';
import { BackendOldFan, toOldFan } from '@/staff/secondary/old-fan/BackendOldFan';
import { OrderDeliveryStatus } from '@/staff/domain/order/OrderDeliveryStatus';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';

export interface BackendOrder {
  id: string;
  exclusives: BackendExclusiveOrder[];
  clubSlug: string;
  fan: BackendOldFan;
  originalCost: BackendPrice;
  usedCoins: BackendPrice;
  finalCost: BackendPrice;
  date: string;
  deliveryStatus: OrderDeliveryStatus;
}

export const toOrder = (backendOrder: BackendOrder): Order => ({
  id: backendOrder.id,
  date: new Date(backendOrder.date),
  clubSlug: backendOrder.clubSlug,
  fan: toOldFan(backendOrder.fan),
  exclusives: backendOrder.exclusives.map(exclusive => toExclusiveOrder(exclusive)),
  deliveryStatus: backendOrder.deliveryStatus,
  originalCost: toTokens(backendOrder.originalCost),
  usedCoins: toTokens(backendOrder.usedCoins),
  finalCost: toTokens(backendOrder.finalCost),
});

export const sortOrderByDateDesc = (order1: Order, order2: Order) => order2.date.getTime() - order1.date.getTime();
