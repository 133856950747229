include ../../../club/loading-table.mixin.pug

template(v-if='giveawayParticipations.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!giveawayParticipations.value().length' data-test='no-giveaway-participations') {{ t('giveawayResults.participations.noGiveawayParticipations')}}
  .fp-vertical-spacing.-s-sm(v-else data-test='giveaway-participations-table')
    .fp-vertical-spacing--line
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-items-end.-equivalent
            .fp-horizontal-spacing--column(v-if='hasAnyQuestion')
              label.fp-field {{ t('giveawayResults.participations.coinsAmountByRightAnswer')}}
                input.fp-input-text(v-model='coinsAmountByRightAnswer' :disabled='!closable' @input='onCoinsAmountByRightAnswerInput' type='number' step=1 min=0 value=0 data-test='coins-amount-by-right-answer')
            .fp-horizontal-spacing--column
              .fp-vertical-spacing
                .fp-vertical-spacing--line.-align-right
                  span.fp-text.-text-sm {{ t('giveawayResults.participations.numberOfParticipations')}}&nbsp;
                  span.fp-text.-text-lg.-text-semi-bold(data-test='number-of-participations') {{ giveawayParticipations.value().length }}
    .fp-vertical-spacing--line
      table.fp-table(data-test='giveaway-participations-fp-table')
        thead
          tr
            th(v-if='hasAnyQuestion' data-test='line-trigger-header')
            th {{ t('giveawayResults.participations.date') }}
            th {{ t('giveawayResults.participations.email')}}
            th(v-if='hasAnyQuestion' data-test='number-of-right-answers-header') {{ t('giveawayResults.participations.numberOfRightAnswers')}}
            th
        tbody
          template(v-for='(participation, index) in giveawayParticipations.value()' :key='index')
            tr(:data-test='`giveaway-participation-${index}`')
              td(v-if='hasAnyQuestion' data-test='line-trigger')
                label.fp-table--expanded-line-trigger(:for='`giveaway-participation-${index}`')
                .fp-icon.fp-glyph-chevron-down.-s12.-color-shade-400
              td(data-test='date') {{ participation.date }}
              td(data-test='email') {{ participation.email }}
              td(v-if='hasAnyQuestion' data-test='number-of-right-answers') {{ participation.numberOfRightAnswers }}
              td
                .fp-horizontal-spacing.-items-center.-justify-end.-s-sm
                  template(v-if='hasAnyQuestion')
                    .fp-horizontal-spacing--column
                      .fp-vertical-spacing.-s-none
                        .fp-vertical-spacing--line.-align-right
                          span.fp-text.-text-semi-bold(data-test='right-answers-gift-amount') {{ giveawayParticipationResultFor(participation).rightAnswersGift.coins.text }}
                        .fp-vertical-spacing--line.-align-right
                          span.fp-text.-text-sm(data-test='right-answers-gift-fiat') {{ giveawayParticipationResultFor(participation).rightAnswersGift.totalCost.text }}
                    .fp-horizontal-spacing--column +
                  .fp-horizontal-spacing--column
                    input.fp-input-text(v-model='extraGiftByParticipationId[participation.id]' :disabled='!closable' @input='event => onExtraGiftInput(participation)' type='number' step=1 min=0 value=0 data-test='extra-gift')
                  .fp-horizontal-spacing--column =
                  .fp-horizontal-spacing--column
                    .fp-vertical-spacing.-s-none
                      .fp-vertical-spacing--line.-align-right
                        span.fp-text.-text-semi-bold(data-test='total-gift-amount-coins') {{ giveawayParticipationResultFor(participation).totalGifts.coins.text }}
                      .fp-vertical-spacing--line.-align-right
                        span.fp-text.-text-sm(data-test='total-gift-amount-fiat') {{ giveawayParticipationResultFor(participation).totalGifts.totalCost.text }}
            tr.fp-table--expanded-line(v-if='hasAnyQuestion' :data-test='`giveaway-participation-results-${index}`')
              td
              td(colspan=4)
                input.fp-table--expanded-line-checkbox(type='checkbox' name='expandable-table' :id='`giveaway-participation-${index}`')
                .fp-table--expanded-line-content
                  GiveawayParticipationAnswersVue(:giveaway-participation='participation')
        tfoot
          tr
            th(colspan='5')
              .fp-horizontal-spacing.-items-center.-justify-end.-s-sm
                .fp-horizontal-spacing--column
                  .fp-vertical-spacing
                    template(v-if='hasAnyQuestion')
                      .fp-vertical-spacing--line.-align-right(data-test='total-right-answers-gifts-amount-title')
                        span.fp-text.-text-sm.-text-regular {{ t('giveawayResults.participations.totalRightAnswersGifts')}}
                      .fp-vertical-spacing--line.-align-right
                        span.fp-text.-text-sm.-text-regular {{ t('giveawayResults.participations.totalExtraGifts')}}
                    .fp-vertical-spacing--line.-align-right
                      span.fp-text.-text-sm.-text-semi-bold {{ t('giveawayResults.participations.totalGifts')}}
                .fp-horizontal-spacing--column
                  .fp-vertical-spacing
                    template(v-if='hasAnyQuestion')
                      .fp-vertical-spacing--line.-align-right
                        span.fp-text.-text-semi-bold(data-test='total-right-answers-gifts-amount') {{ giveawayParticipationResultsTotal.rightAnswersGifts.coins.text }}
                        span.fp-text.-text-sm.-text-regular(data-test='total-right-answers-gifts-fiat') &nbsp;/ {{ giveawayParticipationResultsTotal.rightAnswersGifts.totalCost.text }}
                      .fp-vertical-spacing--line.-align-right
                        span.fp-text.-text-semi-bold(data-test='total-extra-gifts-amount') {{ giveawayParticipationResultsTotal.extraGifts.coins.text }}
                        span.fp-text.-text-sm.-text-regular(data-test='total-extra-gifts-fiat') &nbsp;/ {{ giveawayParticipationResultsTotal.extraGifts.totalCost.text }}
                    .fp-vertical-spacing--line.-align-right
                      span.fp-text.-text-semi-bold(data-test='total-gifts-amount') {{ giveawayParticipationResultsTotal.totalGifts.coins.text }}
                      span.fp-text.-text-sm.-text-regular(data-test='total-gifts-fiat') &nbsp;/ {{ giveawayParticipationResultsTotal.totalGifts.totalCost.text }}
