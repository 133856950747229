import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { GiveawayFormUi } from '@/staff/primary/giveaway/GiveawayForm.ui';

export default defineComponent({
  name: 'GiveawayResultsHeader',

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    giveawayForm: {
      type: Object as PropType<GiveawayFormUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
