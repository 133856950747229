import { ProgramToUpdate } from '@/staff/domain/program/ProgramToUpdate';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';
import { ProgramName } from '@/staff/domain/program/ProgramName';

export interface BackendProgramToUpdate {
  slug?: string;
  imageUrl?: string;
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

export const toBackendProgramToUpdate = (programToUpdate: ProgramToUpdate): BackendProgramToUpdate => ({
  slug: programToUpdate.slug.orElseUndefined(),
  imageUrl: programToUpdate.imageUrl.orElseUndefined(),
  nameTranslations: programToUpdate.nameTranslations.map(fromTranslation<ProgramName>),
  descriptionTranslations: programToUpdate.descriptionTranslations.map(fromTranslation<ProgramDescription>),
});
