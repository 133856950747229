import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { GiveawayParticipationUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipation.ui';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { Fiat } from '@/common/domain/token/Fiat';

export interface GiveawayParticipationTotalResultsUi {
  rightAnswersGifts: TokensUi;
  extraGifts: TokensUi;
  totalGifts: TokensUi;
}

const computeTokensUiFor = (coinsAmount: number, coin: Coin) =>
  fromTokens(Tokens.of(new Coins(coinsAmount, coin.ticker)).withTokenCost(Fiat.euro(coin.price)));

const sumOf = (extraGiftsCoinsAmount: number[]) => extraGiftsCoinsAmount.reduce((sum, current) => sum + current, 0);

export const fromParticipationsAndExtraGifts = (
  participations: GiveawayParticipationUi[],
  rightAnswerCoinsAmount: number,
  extraGiftsCoinsAmount: number[],
  coin: Coin
): GiveawayParticipationTotalResultsUi => {
  const totalRightAnswers = sumOf(participations.map(p => p.numberOfRightAnswers));
  const coinsAmountForRightAnswers = totalRightAnswers * rightAnswerCoinsAmount;
  const totalExtraGiftsCoinsAmount = sumOf(extraGiftsCoinsAmount);
  const totalGiftsCoinsAmount = coinsAmountForRightAnswers + totalExtraGiftsCoinsAmount;

  return {
    rightAnswersGifts: computeTokensUiFor(coinsAmountForRightAnswers, coin),
    extraGifts: computeTokensUiFor(totalExtraGiftsCoinsAmount, coin),
    totalGifts: computeTokensUiFor(totalGiftsCoinsAmount, coin),
  };
};
