import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

export default {
  name: 'DropdownItem',

  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    routeLocation: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    dataTest: {
      type: String,
      required: false,
    },
    danger: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
};
