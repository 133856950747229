import { Bid } from '@/staff/domain/exclusive/Bid';
import { fromMember, MemberUi } from '@/staff/primary/fan/Member.ui';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface BidUi {
  id: string;
  bidder: MemberUi;
  price: TokensUi;
  date: Date;
}

export const fromBid = (bid: Bid): BidUi => ({
  id: bid.id,
  bidder: fromMember(bid.bidder),
  price: fromTokens(bid.price),
  date: bid.date,
});
