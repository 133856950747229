import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { BackendPartner, toPartner } from '@/staff/secondary/club/BackendPartner';
import { BackendGiveawayQuestion, toGiveawayQuestion } from '@/staff/secondary/club/giveaway/BackendGiveawayQuestion';
import { Optional } from '@/common/domain/Optional';
import { GiveawayStatus } from '@/staff/domain/club/giveaway/GiveawayStatus';
import { BackendGiveawayClosure, toGiveawayClosure } from '@/staff/secondary/club/giveaway/BackendGiveawayClosure';
import { GiveawayType } from '@/staff/domain/club/giveaway/GiveawayType';

export interface BackendGiveaway {
  id: string;
  type: GiveawayType;
  url: string;
  startDate: string;
  endDate: string;
  status: GiveawayStatus;
  numberOfParticipations: number;
  questions: BackendGiveawayQuestion[];
  partner?: BackendPartner;
  closure?: BackendGiveawayClosure;
}

export const toGiveaway = (backendGiveaway: BackendGiveaway): Giveaway => ({
  id: backendGiveaway.id,
  type: backendGiveaway.type,
  url: backendGiveaway.url,
  startDate: new Date(backendGiveaway.startDate),
  endDate: new Date(backendGiveaway.endDate),
  status: backendGiveaway.status,
  numberOfParticipations: backendGiveaway.numberOfParticipations,
  questions: backendGiveaway.questions.map(toGiveawayQuestion),
  partner: Optional.ofUndefinable(backendGiveaway.partner).map(toPartner),
  closure: Optional.ofUndefinable(backendGiveaway.closure).map(toGiveawayClosure),
});

export const sortGiveawaysByDateDesc = (coinsGift1: Giveaway, coinsGift2: Giveaway) => {
  const startTime2 = coinsGift2.startDate.getTime();
  const startTime1 = coinsGift1.startDate.getTime();

  const startTimeDiff = startTime2 - startTime1;
  if (startTimeDiff === 0) {
    return coinsGift2.endDate.getTime() - coinsGift1.endDate.getTime();
  }
  return startTimeDiff;
};
