import { Donation } from '@/staff/domain/donation/Donation';
import { DateUi } from '@/common/primary/date/Date.ui';
import { FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';

export interface DonationUi {
  id: string;
  fanEmail: string;
  date: DateUi;
  amount: FiatUi;
  loading: boolean;
}

export const fromDonation = (donation: Donation): DonationUi => ({
  id: donation.id,
  date: new DateUi(donation.date),
  fanEmail: donation.fanEmail,
  amount: fromFiat(donation.amount),
  loading: false,
});
