import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { CoinsHoldingsUi, emptyCoinsHoldings, fromCoinsHoldings } from '@/staff/primary/club/coins-holdings-table/CoinsHoldings.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';

export default defineComponent({
  name: 'CoinsHoldingsTable',

  components: { ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const coinsHoldings = ref(Loader.loading<CoinsHoldingsUi>());
    const hasCoinsHoldings = computed(() => !coinsHoldings.value.isLoading() && coinsHoldings.value.value().coinsHoldings.length > 0);

    const club = clubRepository.getCurrentClub();

    onMounted(() => listCoinsHoldings());

    const listCoinsHoldings = () => {
      clubRepository
        .getCoinsHoldings(club.slug, club.coin)
        .then(clubRepository => coinsHoldings.value.loaded(fromCoinsHoldings(clubRepository)))
        .catch(error => {
          coinsHoldings.value.loaded(emptyCoinsHoldings());
          logger.error('Failed to retrieve coinsHoldings', error);
        });
    };

    return {
      coinsHoldings,
      hasCoinsHoldings,
      t,
    };
  },
});
