import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ReferralRepository } from '@/staff/domain/referral/ReferralRepository';
import { ReferralToCreate } from '@/staff/domain/referral/ReferralToCreate';
import { toBackendReferralToCreate } from '@/staff/secondary/referral/BackendReferralToCreate';

export class BackendReferralRepository implements ReferralRepository {
  constructor(private backendCaller: BackendCaller) {}

  async create(clubSlug: ClubSlug, referralToCreate: ReferralToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/referrals`, toBackendReferralToCreate(referralToCreate));
  }
}
