import { Fan } from '@/staff/domain/fan/Fan';
import { KycStatus } from '@/staff/domain/fan/KycStatus';
import { BackendReferral, toReferral } from '@/staff/secondary/fan/BackendReferral';
import { Optional } from '@/common/domain/Optional';
import { BackendCompanyInfo } from '@/staff/secondary/fan/BackendCompanyInfo';

export interface BackendFan {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  kycStatus: KycStatus;
  referral: BackendReferral;
  companyInfo?: BackendCompanyInfo;
}

const temporarilyMapKycStatus = (kycStatus: KycStatus) => {
  if (kycStatus === ('REFERRED' as KycStatus)) {
    return KycStatus.READY_FOR_KYC;
  }

  return kycStatus;
};

export const toFan = (backendFan: BackendFan): Fan => ({
  id: backendFan.id,
  pictureUrl: backendFan.pictureUrl,
  firstName: backendFan.firstName,
  lastName: backendFan.lastName,
  email: backendFan.email,
  kycStatus: temporarilyMapKycStatus(backendFan.kycStatus),
  referral: toReferral(backendFan.referral),
  companyInfo: Optional.ofUndefinable(backendFan.companyInfo),
});
