import { Donations } from '@/staff/domain/donation/Donations';
import { DonationUi, fromDonation } from '@/staff/primary/donation/Donation.ui';
import { emptyFiatUi, FiatUi, fromFiat } from '@/common/primary/token/Fiat.ui';

export interface DonationsUi {
  donations: DonationUi[];
  total: FiatUi;
}

export const fromDonations = (donations: Donations): DonationsUi => ({
  donations: donations.donations.map(fromDonation),
  total: fromFiat(donations.total()),
});

export const emptyDonations = (): DonationsUi => ({
  donations: [],
  total: emptyFiatUi(),
});
