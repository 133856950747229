import { CoinsHolding } from '@/staff/domain/club/CoinsHolding';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface CoinsHoldingUi {
  fanEmail: string;
  amount: TokensUi;
}

export const fromCoinsHolding = (coinsHolding: CoinsHolding): CoinsHoldingUi => ({
  fanEmail: coinsHolding.fanEmail,
  amount: fromTokens(coinsHolding.coins),
});
