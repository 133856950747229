import { defineComponent, PropType } from 'vue';
import { Media } from '@/common/domain/Media';
import { MediasInputVue } from '@/staff/primary/media-input';
import { MediaType } from '@/common/domain/MediaType';

export default defineComponent({
  name: 'MultipleMediasInput',

  components: { MediasInputVue },

  props: {
    medias: {
      type: Array as PropType<Media[]>,
      default: () => [],
    },
    touched: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['updated-medias'],

  setup(_, { emit }) {
    const updateMedia = async (medias: Media[]) => {
      emit('updated-medias', { value: medias });
    };

    return {
      MediaType,
      updateMedia,
    };
  },
});
