import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { BackendOrder, sortOrderByDateDesc, toOrder } from '@/staff/secondary/order/BackendOrder';
import { OrderRepository } from '@/staff/domain/order/OrderRepository';
import { Orders } from '@/staff/domain/order/Orders';
import { Coin } from '@/common/domain/token/Coin';
import { OrderId } from '@/staff/domain/order/OrderId';

export class BackendOrderRepository implements OrderRepository {
  constructor(private backendCaller: BackendCaller) {}

  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<Orders> {
    return this.backendCaller
      .get<BackendOrder[]>(`api/clubs/${clubSlug}/orders`)
      .then(orders => new Orders(orders.data.map(toOrder).sort(sortOrderByDateDesc), coin));
  }

  async deliver(clubSlug: ClubSlug, orderId: OrderId): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/orders/${orderId}/deliveries`);
  }
}
