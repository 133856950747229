import { Loader } from '@/common/primary/loader/Loader';
import { orderRepositoryKey } from '@/staff/domain/order/OrderRepository';
import { emptyOrders, fromOrders, OrdersUi } from '@/staff/primary/order/Orders.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { OrderUi } from '@/staff/primary/order/Order.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { loggerKey } from '@/common/domain/Logger';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';
import { RelativeTimeVue } from '@/common/primary/relative-time';

export default defineComponent({
  name: 'OrdersTable',

  components: { RelativeTimeVue, ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const orderRepository = inject(orderRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const hasOrders = computed(() => !orders.value.isLoading() && orders.value.value().orders.length > 0);
    const showDeliveredOrders = ref(true);
    const orders = ref(Loader.loading<OrdersUi>());

    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(() => listOrders());

    const listOrders = () => {
      orderRepository
        .getByClub(club.slug, club.coin)
        .then(repositoryOrders => orders.value.loaded(fromOrders(repositoryOrders)))
        .catch(error => {
          orders.value.loaded(emptyOrders());
          logger.error('Failed to retrieve orders', error);
        });
    };

    const filteredOrders = computed(() => {
      if (showDeliveredOrders.value) {
        return orders.value.value().orders;
      }

      return orders.value.value().orders.filter(order => !order.delivered);
    });

    const deliver = async (order: OrderUi, event: Event) => {
      if (!globalWindow.confirm(t('home.orders.confirmDeliver'))) {
        event.preventDefault();
        return;
      }

      order.loading = true;
      await orderRepository.deliver(club.slug, order.id);
      order.delivered = true;
      order.loading = false;
    };

    return {
      deliver,
      club,
      filteredOrders,
      showDeliveredOrders,
      hasOrders,
      orders,
      t,
    };
  },
});
