PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center.-s-sm
        .fp-horizontal-spacing--column
          i.fp-icon.fp-glyph-users.-s18
        .fp-horizontal-spacing--column.-expand
          h2.fp-title.-color-shade-700 {{ t('fans.title') }}
    .fp-vertical-spacing--line
      FansTableVue
