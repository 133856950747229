import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { BackendDonation, sortDonationByDateDesc, toDonation } from '@/staff/secondary/donation/BackendDonation';
import { DonationRepository } from '@/staff/domain/donation/DonationRepository';
import { Donations } from '@/staff/domain/donation/Donations';

export class BackendDonationRepository implements DonationRepository {
  constructor(private backendCaller: BackendCaller) {}

  getByClub(clubSlug: ClubSlug): Promise<Donations> {
    return this.backendCaller
      .get<BackendDonation[]>(`api/clubs/${clubSlug}/donations`)
      .then(donations => new Donations(donations.data.map(toDonation).sort(sortDonationByDateDesc)));
  }
}
