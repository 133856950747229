import { Auction } from '@/staff/domain/exclusive/Auction';
import { AuctionStatus } from '@/staff/domain/exclusive/AuctionStatus';

export interface BackendAuction {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: AuctionStatus;
  anonymous: boolean;
}

export const toAuction = (backendAuction: BackendAuction): Auction => ({
  id: backendAuction.id,
  name: backendAuction.name,
  startDate: new Date(backendAuction.startDate),
  endDate: new Date(backendAuction.endDate),
  status: backendAuction.status,
  anonymous: backendAuction.anonymous,
});
