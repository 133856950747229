import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { BusCallback } from '@/common/domain/BusCallback';
import { SidebarBus } from '@/common/domain/sidebar/SidebarBus';
import { SidebarOpened } from '@/common/domain/sidebar/SidebarOpened';

const OPEN_SIDEBAR = 'open-sidebar';
const CLOSE_SIDEBAR = 'close-sidebar';

export class MittSidebarBus implements SidebarBus {
  constructor(private emitter: Emitter<any>) {}

  open<T>(sidebar: SidebarOpened<T>): void {
    this.emitter.emit(OPEN_SIDEBAR, sidebar);
  }

  close(): void {
    this.emitter.emit(CLOSE_SIDEBAR);
  }

  opened(callback: BusCallback<SidebarOpened<any>>): Unsubscribe {
    const handler: Handler<SidebarOpened<any>> = sidebar => callback(sidebar);
    this.emitter.on(OPEN_SIDEBAR, handler);
    return () => this.emitter.off(OPEN_SIDEBAR, handler);
  }

  closed(callback: BusCallback<void>): Unsubscribe {
    const handler: Handler = () => callback();
    this.emitter.on(CLOSE_SIDEBAR, handler);
    return () => this.emitter.off(CLOSE_SIDEBAR, handler);
  }
}
