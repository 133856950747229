import { ExclusiveOrder } from '@/staff/domain/exclusive/ExclusiveOrder';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface ExclusiveOrderUi {
  id: string;
  name: string;
  slug: string;
  description: string;
  imageUrl: string;
  category: string;
  price: TokensUi;
}

export const fromExclusiveOrder = (exclusive: ExclusiveOrder): ExclusiveOrderUi => ({
  id: exclusive.id,
  name: exclusive.name,
  slug: exclusive.slug,
  category: exclusive.category,
  description: exclusive.description,
  imageUrl: exclusive.imageUrl,
  price: fromTokens(exclusive.pricing),
});
