import { GiveawayQuestion } from '@/staff/domain/club/giveaway/GiveawayQuestion';
import { BackendGiveawayAnswer, toGiveawayAnswer } from '@/staff/secondary/club/giveaway/BackendGiveawayAnswer';
import { Optional } from '@/common/domain/Optional';

export interface BackendGiveawayQuestion {
  id: string;
  title: string;
  preTitle?: string;
  answers: BackendGiveawayAnswer[];
  rightAnswer?: BackendGiveawayAnswer;
  required: boolean;
  quizMode: boolean;
  multiline: boolean;
}

export const toGiveawayQuestion = (backendGiveawayQuestion: BackendGiveawayQuestion): GiveawayQuestion => {
  const answers = backendGiveawayQuestion.answers.map(toGiveawayAnswer);

  return {
    id: backendGiveawayQuestion.id,
    title: backendGiveawayQuestion.title,
    answers,
    rightAnswer: Optional.ofUndefinable(answers.find(answer => answer.text === backendGiveawayQuestion.rightAnswer?.text)),
    required: backendGiveawayQuestion.required,
    quizMode: backendGiveawayQuestion.quizMode,
    multiline: backendGiveawayQuestion.multiline,
    preTitle: Optional.ofEmpty(backendGiveawayQuestion.preTitle),
  };
};
