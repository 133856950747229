import { Fiat } from '@/common/domain/token/Fiat';
import { CoinsGift } from '@/staff/domain/club/CoinsGift';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { CoinsGiftStatus } from '@/staff/domain/club/CoinsGiftStatus';

export class CoinsGifts {
  constructor(
    public readonly gifts: CoinsGift[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    return this.sumToTokens(this.gifts);
  }

  totalNotExpiredTokens(): Tokens {
    return this.sumToTokens(this.gifts.filter(gift => gift.status !== CoinsGiftStatus.EXPIRED));
  }

  private sumToTokens(coinsGifts: CoinsGift[]) {
    const coins = new Coins(
      coinsGifts.reduce((total, gift) => gift.coins.coins.amount + total, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
