import { defineAsyncComponent, markRaw } from 'vue';

export const closeGiveawayConfirmationModal = () =>
  markRaw(
    defineAsyncComponent(
      () => import('@/staff/primary/giveaway/giveaway-results/close-giveaway-confirmation-modal/CloseGiveawayConfirmationModal.vue')
    )
  );
export const giveCoinsModal = () =>
  markRaw(defineAsyncComponent(() => import('@/staff/primary/club/coins-gifts-table/give-coins-modal/GiveCoinsModal.vue')));

export const missingAnswersFormModal = () =>
  markRaw(
    defineAsyncComponent(
      () =>
        import('@/staff/primary/giveaway/giveaway-results/giveaway-missing-answers/missing-answers-form-modal/MissingAnswersFormModal.vue')
    )
  );

export const createReferralModal = () =>
  markRaw(defineAsyncComponent(() => import('@/staff/primary/fan/create-referral-modal/CreateReferralModal.vue')));
