.fp-converter(data-test='converter')
  label.fp-converter-input
    .fp-converter-input--ticker EUR
    input.fp-converter-input--input(type='number' min='0' step='0.01' v-model='fiatPrice' @input='updatedFiatAmount' @focus='$event.target.select()' :class='{"-touched": isUpdating && hasCoinsAmountChanged()}' data-test='fiat-amount' required)
    .fp-converter-input--icon
      i.fp-icon.fp-glyph-euro.-s18
  template(v-if='!fiatOnly')
    label.fp-converter-input
      .fp-converter-input--ticker {{ coin.ticker }}
      input.fp-converter-input--input(type='number' min='0' v-model='coinsPrice' @input='updatedCoinsAmount' @focus='$event.target.select()' :class='{"-touched": isUpdating && hasCoinsAmountChanged()}' data-test='coins-amount' required)
      .fp-converter-input--icon
        i.fp-icon.fp-glyph-coin.-s18
    .fp-converter--icon
      i.fp-icon.fp-glyph-arrows-down-up.-s18
