import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { GiveawayExtraGift } from '@/staff/domain/club/giveaway/GiveawayExtraGift';

export interface BackendGiveawayExtraGift {
  gift: BackendPrice;
  participationId: string;
}

export const toGiveawayExtraGift = (backendExtraGift: BackendGiveawayExtraGift): GiveawayExtraGift => ({
  gift: toTokens(backendExtraGift.gift),
  participationId: backendExtraGift.participationId,
});
