import {
  fromGiveawayExtraGift,
  GiveawayExtraGiftUi,
  toGiveawayExtraGiftToCreate,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayExtraGift.ui';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { GiveawayClosure } from '@/staff/domain/club/giveaway/GiveawayClosure';
import { Coin } from '@/common/domain/token/Coin';
import { Coins } from '@/common/domain/token/Coins';
import { Tokens } from '@/common/domain/token/Tokens';
import { Fiat } from '@/common/domain/token/Fiat';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { GiveawayStatus } from '@/staff/domain/club/giveaway/GiveawayStatus';

export interface GiveawayClosureUi {
  giftByRightAnswer: TokensUi;
  extraGifts: GiveawayExtraGiftUi[];
  closable: boolean;
}

export const toGiveawayClosureToCreate = (clubSlug: string, giveawayId: string, closure: GiveawayClosureUi): GiveawayClosureToCreate => ({
  clubSlug,
  giveawayId,
  coinsAmountByRightAnswer: closure.giftByRightAnswer.coins.amount,
  extraGifts: closure.extraGifts.map(toGiveawayExtraGiftToCreate),
});

const fromExistingClosure =
  () =>
  (closure: GiveawayClosure): GiveawayClosureUi => ({
    giftByRightAnswer: fromTokens(closure.giftByRightAnswer),
    extraGifts: closure.extraGifts.map(fromGiveawayExtraGift),
    closable: false,
  });

const newClosureFrom = (giveaway: Giveaway, coin: Coin) => ({
  giftByRightAnswer: fromTokens(Tokens.of(new Coins(0, coin.ticker)).withTokenCost(Fiat.euro(coin.price))),
  extraGifts: [],
  closable: giveaway.status === GiveawayStatus.TO_CLOSE,
});

export const fromGiveawayToClosure = (giveaway: Giveaway, coin: Coin): GiveawayClosureUi =>
  giveaway.closure.map(fromExistingClosure()).orElse(newClosureFrom(giveaway, coin));
