PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column.-expand
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-shopping-bag.-s18
            .fp-horizontal-spacing--column
              h2.fp-title.-color-shade-700 {{ t('exclusivesPage.title') }}
        .fp-horizontal-spacing--column
          a.fp-link.-no-text-decoration(:href='marketplaceUrl')
            FairplayerButtonVue(icon='eye' no-border :text='t(\'exclusivesPage.seeMarketplace\')')
        .fp-horizontal-spacing--column
          router-link.fp-link.-no-text-decoration(:to='{ name: \'createExclusive\', params: { clubSlug }}' data-test='create-exclusive')
            FairplayerButtonVue(icon='plus' :text='t(\'exclusivesPage.createExclusive\')')
    .fp-vertical-spacing--line
      ExclusivesTableVue
