import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Optional } from '@/common/domain/Optional';

const clubSlugMatcher = /clubs(\/|%2F)([\da-z-]+)/;

export class UrlParserClubSlugRepository {
  constructor(private window: Window) {}

  getCurrentClubSlug(): Optional<ClubSlug> {
    const href = this.window.location.href;

    const matchingResult = href.match(clubSlugMatcher);

    return Optional.ofUndefinable(matchingResult ? matchingResult[2] : null);
  }
}
