import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendFan, toFan } from '@/staff/secondary/fan/BackendFan';
import { Fan } from '@/staff/domain/fan/Fan';
import { FanRepository } from '@/staff/domain/fan/FanRepository';
import { FanId } from '@/staff/domain/fan/FanId';

export class BackendFanRepository implements FanRepository {
  constructor(private backendCaller: BackendCaller) {}

  async list(clubSlug: ClubSlug): Promise<Fan[]> {
    return this.backendCaller.get<BackendFan[]>(`api/clubs/${clubSlug}/fans`).then(fans => fans.data.map(toFan));
  }

  async approveDueDiligence(clubSlug: ClubSlug, fanId: FanId): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/due-diligence-approvals`, { fanId });
  }
}
