import { GiveawayExtraGiftToCreate } from '@/staff/domain/club/giveaway/GiveawayExtraGiftToCreate';

export interface BackendGiveawayExtraGiftToCreate {
  coinsAmount: number;
  participationId: string;
}

export const toBackendGiveawayExtraGiftToCreate = (extraGiftToCreate: GiveawayExtraGiftToCreate): BackendGiveawayExtraGiftToCreate => ({
  coinsAmount: extraGiftToCreate.coinsAmount,
  participationId: extraGiftToCreate.participationId,
});
