import { ExclusiveDescriptionDraftRequest } from '@/staff/domain/exclusive/ExclusiveDescriptionDraftRequest';

export interface BackendExclusiveDescriptionDraftRequest {
  exclusiveName: string;
  completionContext?: string;
}

export const toBackendExclusiveDescriptionDraftRequest = (
  exclusiveDescriptionDraftRequest: ExclusiveDescriptionDraftRequest
): BackendExclusiveDescriptionDraftRequest => ({
  exclusiveName: exclusiveDescriptionDraftRequest.exclusiveName,
  completionContext: exclusiveDescriptionDraftRequest.completionContext.orElseUndefined(),
});
