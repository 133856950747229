import { CompanyInfo } from '@/staff/domain/fan/CompanyInfo';

export interface CompanyInfoUi {
  name: string;
  registrationNumber: string;
}

export const fromCompanyInfo = (companyInfo: CompanyInfo): CompanyInfoUi => ({
  name: companyInfo.name,
  registrationNumber: companyInfo.registrationNumber,
});
