.fp-staff-layout
  .fp-staff-layout--menu
    .fp-staff-layout--menu--logo
      img.fp-illustration.-fairplayer-logo
      img.fp-illustration.-logo.-xs
    .fp-staff-layout--menu--items(v-if='!authorizations.isLoading() && clubLoaded' data-test='menu-items')
      router-link.fp-left-menu-item(v-for='(menuItem, index) in menuItems' :key='index' :class='{"-active": currentRouterTabName === menuItem.routerTab}' :to='{ name: menuItem.routeName}' :data-test='`${ menuItem.slug }`')
        i.fp-icon.-s18(:class='`fp-glyph-${menuItem.icon}`')
        .fp-left-menu-item--text {{ t(`menu.${menuItem.translateKey}`) }}
  .fp-staff-layout--body
    AnnouncementVue(v-if='displayDevEnvironmentAnnouncement' :announcement-ui='devEnvironmentAnnouncementUi' @close='hideDevAnnouncement' data-test='dev-announcement')
    router-view(v-if='clubLoaded')
    NotFoundVue(v-if='clubNotFound' :not-found-ui='clubNotFoundUi()')
  .fp-staff-layout--toaster
    ToastsListVue
  ModalVue
