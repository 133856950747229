import { Language } from '@/common/domain/Language';
import { Translation } from '@/common/domain/Translation';
import { TranslatableField } from '@/common/domain/TranslatableField';

export interface TranslationUi {
  language: Language;
  value: string;
}

export const fromTranslation = <T extends TranslatableField>(translation: Translation<T>): TranslationUi => ({
  language: translation.language,
  value: translation.value,
});

export const toTranslation = <T extends TranslatableField>(translation: TranslationUi): Translation<T> => ({
  language: translation.language,
  value: translation.value as T,
});

export const translationFor = (translations: TranslationUi[], language: string): TranslationUi =>
  translations.find(t => t.language === language)!;

const emptyTranslation = (language: Language) => ({ language, value: '' });

const existingTranslationOrEmpty =
  <T extends TranslatableField>(translations: Translation<T>[]) =>
  (language: Language) =>
    translations.find(translation => translation.language === language) || emptyTranslation(language);

const byLanguageCodeDesc = () => (language1: Language, language2: Language) => language2.localeCompare(language1);

export const translationHasChanged =
  <T extends TranslatableField>(translations: Translation<T>[]) =>
  (translation: TranslationUi) => {
    const existingTranslation = translations.find(originalTranslation => originalTranslation.language === translation.language);

    if (!existingTranslation) {
      return true;
    }

    return existingTranslation.value.localeCompare(translation.value) !== 0;
  };

export const fromTranslations = <T extends TranslatableField>(languages: Language[], translations: Translation<T>[]) =>
  languages.sort(byLanguageCodeDesc()).map(existingTranslationOrEmpty(translations)).map(fromTranslation);

export const emptyTranslations = (languages: string[]) => languages.sort(byLanguageCodeDesc()).map(emptyTranslation).map(fromTranslation);
