import { defineComponent, inject, PropType } from 'vue';
import { TokensUi } from '@/common/primary/token/Tokens.ui';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';

export default defineComponent({
  name: 'ExclusivePrice',

  props: {
    pricing: {
      type: Object as PropType<TokensUi>,
      required: true,
    },
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const hideCoinsPrice = !club.coinsEnabled;

    return { hideCoinsPrice };
  },
});
