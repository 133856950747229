PageVue
  .fp-vertical-spacing
    CoinsGiftsTableVue
    .fp-separator.-secondary
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          i.fp-icon.fp-glyph-wallet.-s18
        .fp-horizontal-spacing--column
          h2.fp-title.-color-shade-700 {{ t('coinsHoldings.title') }}
    .fp-vertical-spacing--line
      CoinsHoldingsTableVue
