import { Program } from '@/staff/domain/program/Program';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { fromTranslation, TranslationUi } from '@/common/primary/Translation.ui';
import { ProgramName } from '@/staff/domain/program/ProgramName';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export interface ProgramUi {
  slug: string;
  name: string;
  imageUrl: string;
  description: string;
  nameTranslations?: TranslationUi[];
  descriptionTranslations?: TranslationUi[];
}

export const fromProgram = (program: Program): ProgramUi => ({
  slug: program.slug,
  name: program.name,
  imageUrl: program.imageUrl,
  description: renderMarkdown(program.description),
  nameTranslations: program.nameTranslations?.map(fromTranslation<ProgramName>),
  descriptionTranslations: program.descriptionTranslations?.map(fromTranslation<ProgramDescription>),
});
