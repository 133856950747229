import { Partner } from '@/staff/domain/club/Partner';
import { ComposerTranslation } from 'vue-i18n';

export interface PartnerUi {
  name: string;
  slug: string;
}

export const fromPartner = (partner: Partner): PartnerUi => ({
  name: partner.name,
  slug: partner.slug,
});

export const nonePartner = (t: ComposerTranslation): PartnerUi => ({
  name: t('giveawayForm.partner.none'),
  slug: '',
});
