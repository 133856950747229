import { notFoundUi, NotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';

export default {
  name: 'NotFound',

  components: { FairplayerButtonVue },

  props: {
    notFoundUi: {
      type: Object as PropType<NotFoundUi>,
      default: notFoundUi(),
    },
  },

  setup(props: any) {
    const { t } = useI18n();
    const router = useRouter();

    const goTo = () => router.push({ name: props.notFoundUi.routeName });

    return {
      goTo,
      t,
    };
  },
};
