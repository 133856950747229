import { Referral } from '@/staff/domain/fan/Referral';
import { ReferralId } from '@/staff/domain/fan/ReferralId';
import { DateUi } from '@/common/primary/date/Date.ui';

export interface ReferralUi {
  id: ReferralId;
  bypassDueDiligence: boolean;
  bypassMembershipPayment: boolean;
  creationDate: DateUi;
  referrerId?: string;
}

export const fromReferral = (referral: Referral): ReferralUi => ({
  id: referral.id,
  bypassDueDiligence: referral.bypassDueDiligence,
  bypassMembershipPayment: referral.bypassMembershipPayment,
  creationDate: new DateUi(referral.creationDate),
  referrerId: referral.referrerId.orElseUndefined(),
});
