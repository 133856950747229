import { createApp } from 'vue';
import App from '@/staff/primary/app/App.vue';
import router from './router/router';
import { getProvider, provideAll } from '@/providers';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { authenticationGuard } from '@/AuthenticationGuard';
import { createFairplayerI18n } from '@/i18n';
import { stafferLanguageRepositoryKey } from '@/common/domain/StafferLanguageRepository';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { initSentry } from '@/sentry';

const app = createApp(App);
app.use(router);
initSentry(app);
app.use(createFairplayerI18n(getProvider(stafferLanguageRepositoryKey)));

provideAll(app.provide);

router.beforeResolve(authenticationGuard(getProvider(authenticationKey), getProvider(loginContextRepositoryKey)));

app.mount('#app');
