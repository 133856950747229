import { Fiat } from '@/common/domain/token/Fiat';
import { Donation } from '@/staff/domain/donation/Donation';

export class Donations {
  constructor(public readonly donations: Donation[]) {}

  total(): Fiat {
    return Fiat.euro(this.donations.reduce((total, donation) => total + donation.amount.value, 0));
  }
}
