import { Post } from '@/staff/domain/post/Post';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { DateUi } from '@/common/primary/date/Date.ui';
import { fromTranslation, TranslationUi } from '@/common/primary/Translation.ui';

export interface PostUi {
  slug: string;
  name: string;
  imageUrl: string;
  description: string;
  date: DateUi;
  nameTranslations?: TranslationUi[];
  descriptionTranslations?: TranslationUi[];
  programSlug?: string;
}

export const fromPost = (post: Post): PostUi => ({
  slug: post.slug,
  name: post.name,
  imageUrl: post.imageUrl,
  description: renderMarkdown(post.description),
  date: new DateUi(post.date),
  nameTranslations: post.nameTranslations?.map(fromTranslation),
  descriptionTranslations: post.descriptionTranslations?.map(fromTranslation),
  programSlug: post.programSlug.orElseUndefined(),
});
