import { GiveawayExtraGiftToCreate } from '@/staff/domain/club/giveaway/GiveawayExtraGiftToCreate';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { GiveawayExtraGift } from '@/staff/domain/club/giveaway/GiveawayExtraGift';

export interface GiveawayExtraGiftUi {
  gift: TokensUi;
  participationId: string;
}

export const toGiveawayExtraGiftToCreate = (extraGift: GiveawayExtraGiftUi): GiveawayExtraGiftToCreate => ({
  coinsAmount: extraGift.gift.coins.amount,
  participationId: extraGift.participationId,
});

export const fromGiveawayExtraGift = (extraGift: GiveawayExtraGift): GiveawayExtraGiftUi => ({
  gift: fromTokens(extraGift.gift),
  participationId: extraGift.participationId,
});
