import { Authorization } from '@/staff/domain/staffer/Authorization';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Username } from '@/common/domain/Username';
import { InjectionKey } from 'vue';

export const stafferRepositoryKey: InjectionKey<StafferRepository> = Symbol();

export interface StafferRepository {
  register(username: Username): Promise<void>;
  getAuthorizationsFor(username: Username, clubSlug: ClubSlug): Promise<Authorization[]>;
}
