import { defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { GiveawayResultsChartVue } from './giveaway-results-chart';
import { useI18n } from 'vue-i18n';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { Loader } from '@/common/primary/loader/Loader';
import {
  fromGiveawayQuestionStatistics,
  GiveawayQuestionStatisticsUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-results-statistics/giveaway-results-chart/GiveawayQuestionStatistics.ui';
import { giveawayParticipationRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'GiveawayResultsStatistics',

  components: { GiveawayResultsChartVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    giveawayId: {
      type: String,
      required: true,
    },
    giveawayQuestions: {
      type: Array as PropType<GiveawayQuestionFormUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const giveawayParticipationRepository = inject(giveawayParticipationRepositoryKey)!;

    const selectedQuestion = ref(0);
    const questionsStatistics = ref(Loader.loading<GiveawayQuestionStatisticsUi[]>());

    onMounted(async () => {
      giveawayParticipationRepository
        .getQuestionsStatistics(props.clubSlug, props.giveawayId)
        .then(statistics => questionsStatistics.value.loaded(statistics.map(fromGiveawayQuestionStatistics)))
        .catch(error => {
          questionsStatistics.value.loaded([]);
          logger.error('Failed to retrieve statistics', error);
        });
    });

    const onQuestionClick = async (index: number) => {
      selectedQuestion.value = index;
    };

    return {
      t,
      selectedQuestion,
      questionsStatistics,
      onQuestionClick,
    };
  },
});
