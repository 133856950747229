import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubStatisticsRepository } from '@/staff/domain/club/statistics/ClubStatisticsRepository';
import { GlobalStatistics } from '@/staff/domain/club/statistics/GlobalStatistics';
import { BackendOrderRepository } from '@/staff/secondary/order/BackendOrderRepository';
import { Coin } from '@/common/domain/token/Coin';
import { BackendDonationRepository } from '@/staff/secondary/donation/BackendDonationRepository';

export class FairplayerClubStatisticsRepository implements ClubStatisticsRepository {
  constructor(
    private backendOrderRepository: BackendOrderRepository,
    private backendDonationRepository: BackendDonationRepository
  ) {}

  async getSalesAmount(clubSlug: ClubSlug, coin: Coin): Promise<GlobalStatistics> {
    const [orders, donations] = await Promise.all([
      this.backendOrderRepository.getByClub(clubSlug, coin),
      this.backendDonationRepository.getByClub(clubSlug),
    ]);
    return new GlobalStatistics(orders, donations);
  }
}
