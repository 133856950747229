import { Club } from '@/staff/domain/club/Club';
import { ClubState } from '@/staff/domain/club/ClubState';
import { BackendGiveaway, toGiveaway } from '@/staff/secondary/club/giveaway/BackendGiveaway';
import { Coin } from '@/common/domain/token/Coin';
import { BackendPartner, toPartner } from '@/staff/secondary/club/BackendPartner';
import { Language } from '@/common/domain/Language';
import { ClubModel } from '@/staff/domain/club/ClubModel';

export interface BackendClub {
  id: number;
  name: string;
  slug: string;
  model: ClubModel;
  logoUrl: string;
  bannerUrl: string;
  webappUrl: string;
  ticker: string;
  currentPrice: number;
  state: ClubState;
  referralNeeded: boolean;
  coinsEnabled: boolean;
  fiatAccepted: boolean;
  languages: Language[];
  activeGiveaways: BackendGiveaway[];
  partners: BackendPartner[];
}

const toCoin = (ticker: string, price: number): Coin => ({
  ticker,
  price,
});

export const toClub = (backendClub: BackendClub): Club => ({
  id: backendClub.id,
  name: backendClub.name,
  slug: backendClub.slug,
  model: backendClub.model,
  logoUrl: backendClub.logoUrl,
  bannerUrl: backendClub.bannerUrl,
  webappUrl: backendClub.webappUrl,
  coin: toCoin(backendClub.ticker, backendClub.currentPrice),
  state: backendClub.state,
  referralNeeded: backendClub.referralNeeded,
  coinsEnabled: backendClub.coinsEnabled,
  fiatAccepted: backendClub.fiatAccepted,
  languages: backendClub.languages,
  activeGiveaways: backendClub.activeGiveaways.map(toGiveaway),
  partners: backendClub.partners.map(toPartner),
});
