import { AlertType } from '@/common/domain/alert/AlertType';
import { globalWindowKey } from '@/common/domain/Window';
import { ToastUi } from '@/staff/primary/toasts/Toast.ui';
import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';

const TIMEOUT_DELAY = 5000;

const icons: Record<AlertType, string> = {
  danger: 'fp-glyph-close-circle',
  info: 'fp-glyph-info',
  success: 'fp-glyph-check',
  warning: 'fp-glyph-warning',
};

export default defineComponent({
  name: 'Toast',
  props: {
    toast: {
      type: Object as PropType<ToastUi>,
      required: true,
    },
  },

  emits: ['closed'],

  setup(props, { emit }) {
    const globalWindow = inject(globalWindowKey)!;

    const computeIcon = (): string => icons[props.toast.type];

    const nowIntervalId = ref(0);
    const timeoutRunning = ref(false);
    const timerStartTime = ref(0);

    const now = ref(Date.now());

    const icon = computed<string>(() => computeIcon());
    const progressBarWidth = computed<number>(() => {
      if (!timeoutRunning.value) {
        return 100;
      }
      return 100 - ((now.value - timerStartTime.value) / TIMEOUT_DELAY) * 100;
    });

    const timeoutId = ref(0);

    const close = () => emit('closed');

    const enter = () => {
      timeoutRunning.value = false;
      globalWindow.clearTimeout(timeoutId.value);
    };

    const leave = () => {
      delayClose();
    };

    const delayClose = () => {
      timeoutRunning.value = true;
      timerStartTime.value = Date.now();
      timeoutId.value = globalWindow.setTimeout(() => close(), TIMEOUT_DELAY);
    };

    const delayNow = () => {
      now.value = Date.now();
    };

    onMounted(() => {
      delayClose();
      nowIntervalId.value = globalWindow.setInterval(delayNow, 100);
    });

    onUnmounted(() => {
      globalWindow.clearInterval(nowIntervalId.value);
    });

    return { close, enter, icon, leave, timeoutId, progressBarWidth };
  },
});
