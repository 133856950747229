import { Loader } from '@/common/primary/loader/Loader';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import { emptyExclusives, ExclusivesUi, fromExclusives } from '@/staff/primary/exclusive/exclusives-table/Exclusives.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';

export default defineComponent({
  name: 'ExclusivesTable',

  components: { DropdownVue, DropdownSeparatorVue, DropdownItemVue, ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;

    const exclusives = ref(Loader.loading<ExclusivesUi>());
    const terms = ref('');
    const showNotVisibleExclusives = ref(true);

    const club = fromClub(clubRepository.getCurrentClub());

    const hasExclusives = computed(() => !exclusives.value.isLoading() && exclusives.value.value().exclusives.length > 0);

    const filteredExclusives = computed(() => {
      let exclusivesList = exclusives.value.value().exclusives;
      if (!showNotVisibleExclusives.value) {
        exclusivesList = exclusivesList.filter(exclusive => exclusive.visible);
      }
      if (terms.value) {
        const termsLowered = terms.value.toLowerCase();
        exclusivesList = exclusivesList.filter(
          exclusive => exclusive.slug.includes(termsLowered) || exclusive.name.toLowerCase().includes(termsLowered)
        );
      }
      return exclusivesList;
    });

    onMounted(() => listExclusives());

    const listExclusives = () => {
      exclusiveRepository
        .getByClub(club.slug, club.coin)
        .then(exclusiveRepository => exclusives.value.loaded(fromExclusives(exclusiveRepository)))
        .catch(error => {
          exclusives.value.loaded(emptyExclusives());
          logger.error('Failed to retrieve exclusives', error);
        });
    };

    return {
      club,
      exclusives,
      filteredExclusives,
      hasExclusives,
      showNotVisibleExclusives,
      terms,
      t,
    };
  },
});
