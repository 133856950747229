import { FansStatistics } from '@/staff/domain/club/FansStatistics';

export interface FansStatisticsUi {
  totalFans: number;
  giveawayParticipations: number;
}

export const fromFansStatistics = (fansStatistics: FansStatistics): FansStatisticsUi => ({
  totalFans: fansStatistics.totalFans,
  giveawayParticipations: fansStatistics.giveawayParticipations,
});
