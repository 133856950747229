import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { PresignedUpload } from '@/staff/domain/upload/PresignedUpload';
import { UploadRepository } from '@/staff/domain/upload/UploadRepository';
import { S3Repository } from '@/staff/secondary/upload/S3Repository';
import { BackendClubRepository } from '@/staff/secondary/club/BackendClubRepository';
import { FileContentType } from '@/common/domain/FileContentType';

export class FairplayerUploadRepository implements UploadRepository {
  constructor(
    private s3Repository: S3Repository,
    private backendClubRepository: BackendClubRepository
  ) {}

  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload> {
    return this.backendClubRepository.createPresignedUpload(clubSlug, contentType);
  }

  uploadFileToStorage(file: File, presignedUrl: string): Promise<void> {
    return this.s3Repository.uploadFileToStorage(file, presignedUrl);
  }
}
