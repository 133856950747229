import { Alerted } from '@/common/domain/alert/Alerted';
import { AlertMessage } from '@/common/domain/alert/AlertMessage';
import { AlertType } from '@/common/domain/alert/AlertType';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { InjectionKey } from 'vue';

export const alertBusKey: InjectionKey<AlertBus> = Symbol();

export interface AlertBus {
  onAlert(alerted: Alerted): Unsubscribe;

  alert(alertMessage: AlertMessage, alertType: AlertType): void;
}
