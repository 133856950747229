import { Bid } from '@/staff/domain/exclusive/Bid';
import { BackendMember, toMember } from '@/staff/secondary/fan/BackendMember';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';

export interface BackendBid {
  id: string;
  bidder: BackendMember;
  price: BackendPrice;
  date: string;
}

export const toBid = (backendBid: BackendBid): Bid => ({
  id: backendBid.id,
  bidder: toMember(backendBid.bidder),
  price: toTokens(backendBid.price),
  date: new Date(backendBid.date),
});
