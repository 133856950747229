import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { Loader } from '@/common/primary/loader/Loader';
import { fromProgram, ProgramUi } from '@/staff/primary/program/Program.ui';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';

export default defineComponent({
  name: 'ProgramsTable',

  components: { DropdownVue, DropdownItemVue, DropdownSeparatorVue },

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const programRepository = inject(programRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const programsList = ref(Loader.loading<ProgramUi[]>());
    const hasPrograms = computed(() => !programsList.value.isLoading() && programsList.value.value().length > 0);

    onMounted(async () => {
      await listPrograms();
    });

    const listPrograms = async () => {
      await programRepository
        .list(club.slug)
        .then(programs => programsList.value.loaded(programs.map(fromProgram)))
        .catch(error => {
          programsList.value.loaded([]);
          logger.error('Failed to retrieve programs', error);
        });
    };

    return {
      programsList,
      hasPrograms,
      club,
      t,
    };
  },
});
