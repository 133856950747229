import { defineComponent, PropType, ref, watch } from 'vue';
import { config, Footers, MdEditor, ToolbarNames } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { MarkdownTextUi } from '@/common/primary/markdown/MarkdownText.ui';
import FR_FR from '@vavt/cm-extension/dist/locale/fr-FR';

const toolbars: ToolbarNames[] = [
  'bold',
  'underline',
  'italic',
  'strikeThrough',
  '-',
  'title',
  'quote',
  'unorderedList',
  'orderedList',
  '-',
  'link',
  'image',
  'table',
  '-',
  'revoke',
  'next',
];

const footers: Footers[] = [];

config({
  editorConfig: {
    languageUserDefined: {
      'fr-FR': FR_FR,
    },
  },
});

export default defineComponent({
  name: 'MarkdownEditor',

  components: { MdEditor },

  props: {
    text: {
      type: Object as PropType<MarkdownTextUi>,
      required: true,
    },
    touched: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['updated-value'],

  setup(props, { emit }) {
    watch(
      () => props.text,
      newValue => (textModel.value = ref(newValue.value)),
      { deep: true }
    );

    const textModel = ref(props.text);

    const onTextChange = (newText: string) => {
      emit('updated-value', { value: newText });
    };

    return {
      footers,
      toolbars,
      textModel,
      onTextChange,
    };
  },
});
