export class DateUi {
  constructor(public readonly date: Date) {}

  toHuman(): string {
    return this.date.toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'Europe/Paris',
    });
  }
}
