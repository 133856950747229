import { defineComponent } from 'vue';
import { PageVue } from '@/staff/primary/club-layout/page';
import { useI18n } from 'vue-i18n';
import { PostsTableVue } from '../posts-table';
import { CreatePostVue } from '../create-post';
import { FairplayerButtonVue } from '@/common/primary/button';

export default defineComponent({
  name: 'PostsPage',

  components: {
    CreatePostVue,
    PostsTableVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
