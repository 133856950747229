import { Donation } from '@/staff/domain/donation/Donation';
import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';

export interface BackendDonation {
  id: string;
  fanEmail: string;
  date: string;
  amount: BackendFiat;
}

export const toDonation = (backendDonation: BackendDonation): Donation => ({
  id: backendDonation.id,
  fanEmail: backendDonation.fanEmail,
  date: new Date(backendDonation.date),
  amount: toFiat(backendDonation.amount),
});

export const sortDonationByDateDesc = (donation1: Donation, donation2: Donation) => donation2.date.getTime() - donation1.date.getTime();
