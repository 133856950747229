import { computed, defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatRelativeTime } from '@/common/primary/relative-time/RelativeTimeFormatter';
import { stafferLanguageRepositoryKey } from '@/common/domain/StafferLanguageRepository';

export default defineComponent({
  name: 'RelativeTime',

  props: {
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    sm: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const stafferLanguageRepository = inject(stafferLanguageRepositoryKey)!;

    const relativeTime = computed(() => formatRelativeTime(props.date, stafferLanguageRepository.getCurrentLanguage(), t));

    return {
      relativeTime,
    };
  },
});
