import { Fiat } from '@/common/domain/token/Fiat';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { Coin } from '@/common/domain/token/Coin';

export class Exclusives {
  constructor(
    public readonly exclusives: Exclusive[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    return this.sumToTokens(this.exclusives);
  }

  visibleTotalTokens(): Tokens {
    return this.sumToTokens(this.exclusives.filter(exclusive => exclusive.visible));
  }

  private sumToTokens(exclusives: Exclusive[]): Tokens {
    const coins = new Coins(
      exclusives.reduce((total, exclusive) => exclusive.stock * exclusive.pricing.coins.amount + total, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
