import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { fromPost, PostUi } from '@/staff/primary/post/Post.ui';
import { postRepositoryKey } from '@/staff/domain/post/PostRepository';
import { DropdownItemVue } from '@/common/primary/dropdown/dropdown-item';
import { DropdownVue } from '@/common/primary/dropdown';
import { DropdownSeparatorVue } from '@/common/primary/dropdown/dropdown-separator';

export default defineComponent({
  name: 'PostsTable',

  components: { DropdownVue, DropdownItemVue, DropdownSeparatorVue },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const postRepository = inject(postRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    const postsList = ref(Loader.loading<PostUi[]>());
    const hasPosts = computed(() => !postsList.value.isLoading() && postsList.value.value().length > 0);

    onMounted(async () => {
      await listPosts();
    });

    const listPosts = async () => {
      await postRepository
        .list(club.slug)
        .then(posts => postsList.value.loaded(posts.map(fromPost)))
        .catch(error => {
          postsList.value.loaded([]);
          logger.error('Failed to retrieve posts', error);
        });
    };

    return {
      postsList,
      hasPosts,
      club,
      t,
    };
  },
});
