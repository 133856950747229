import { RouterTab } from '@/router/RouterTab';

export interface MenuItemUi {
  routeName: string;
  translateKey: string;
  slug: string;
  icon: string;
  routerTab: RouterTab;
}

export const overviewItem: MenuItemUi = {
  routeName: 'clubOverview',
  translateKey: 'overview',
  slug: 'overview',
  icon: 'piechart',
  routerTab: RouterTab.OVERVIEW,
};

export const giveawaysItem: MenuItemUi = {
  routeName: 'giveaways',
  translateKey: 'giveaways',
  slug: 'giveaways',
  icon: 'gift',
  routerTab: RouterTab.GIVEAWAYS,
};

export const exclusivesItem: MenuItemUi = {
  routeName: 'exclusives',
  translateKey: 'exclusives',
  slug: 'exclusives',
  icon: 'shopping-bag',
  routerTab: RouterTab.EXCLUSIVES,
};

export const coinsItem: MenuItemUi = {
  routeName: 'coins',
  translateKey: 'coins',
  slug: 'coins',
  icon: 'coin',
  routerTab: RouterTab.COINS,
};

export const programsItem: MenuItemUi = {
  routeName: 'programs',
  translateKey: 'programs',
  slug: 'programs',
  icon: 'newspaper',
  routerTab: RouterTab.PROGRAMS,
};

export const postsItem: MenuItemUi = {
  routeName: 'posts',
  translateKey: 'posts',
  slug: 'posts',
  icon: 'calendar-check',
  routerTab: RouterTab.POSTS,
};

export const fansItem: MenuItemUi = {
  routeName: 'fans',
  translateKey: 'members',
  slug: 'fans',
  icon: 'users',
  routerTab: RouterTab.FANS,
};
