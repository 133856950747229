import { Authentication } from '@/common/domain/auth/Authentication';
import { AuthenticationInMemory } from '@/common/secondary/auth/AuthenticationInMemory';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import ConsoleLogger from '@/common/secondary/ConsoleLogger';
import { AuthenticationCognito, configureCognitoWith } from '@/common/secondary/auth/AuthenticationCognito';
import { Amplify } from 'aws-amplify';
import { CognitoClient } from '@/common/secondary/auth/CognitoClient';

export const createFairplayerAuthentication = async (alertBus: AlertBus, consoleLogger: ConsoleLogger): Promise<Authentication> => {
  if (import.meta.env.VITE_AUTHENTICATION_IN_MEMORY === 'true') {
    return new AuthenticationInMemory();
  }

  configureCognitoWith(Amplify.configure, alertBus);
  return new AuthenticationCognito(new CognitoClient(), alertBus, consoleLogger);
};
