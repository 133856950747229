import { Program } from '@/staff/domain/program/Program';

export interface ProgramSelectEntryUi {
  slug?: string;
  name: string;
}

export const fromProgram = (program: Program): ProgramSelectEntryUi => ({
  slug: program.slug,
  name: program.name,
});
