import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { hasPostFormChanged, PostFormUi, PostTextFormInput } from '@/staff/primary/post/post-form/PostForm.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { Language } from '@/common/domain/Language';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';
import { MultipleMediasInputVue } from '@/staff/primary/media-input/multiple-medias-input';
import { SingleImageInputVue } from '@/staff/primary/media-input/single-image-input';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { fromProgram, ProgramSelectEntryUi } from '@/staff/primary/post/post-form/ProgramSelectEntry.ui';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';

const postNameForSlugMatcher = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

export default defineComponent({
  name: 'PostForm',

  components: { MultipleMediasInputVue, MarkdownEditorVue, SingleImageInputVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<PostFormUi>,
      required: true,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const globalWindow = inject(globalWindowKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const postForm = ref<PostFormUi>(structuredClone(toRaw(props.formValue)));
    const isLoading = ref<boolean>(false);
    const askedCreate = ref<boolean>(false);
    const isTranslationLoading = ref<boolean>(false);
    const draggable = ref(true);
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];
    const programsList = ref<ProgramSelectEntryUi[]>([{ name: t('postForm.program.none'), slug: undefined }]);
    const hasDescription = computed(() => postForm.value.descriptionTranslations[0].value.length > 0);

    const formatDescription = (description: string) => renderMarkdown(description) as string;

    const mainImage = computed((): Media | undefined => {
      if (postForm.value.imageUrl === '') {
        return undefined;
      }
      return {
        url: postForm.value.imageUrl,
        type: MediaType.IMAGE,
      };
    });

    const hasFormChanged = () => computed(() => hasPostFormChanged(postForm.value as PostFormUi, props.formValue));

    onMounted(async () => {
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged().value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
      await listPrograms();
    });

    watch(
      () => [props.formValue],
      () => {
        postForm.value = structuredClone(toRaw(props.formValue));
      }
    );

    const listPrograms = async () =>
      await programRepository.list(props.clubSlug).then(programs => programs.map(program => programsList.value.push(fromProgram(program))));

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasFormChanged().value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const confirm = () => {
      if (postForm.value.descriptionTranslations.some(translation => !translation.value?.trim())) {
        globalWindow.alert(t('postForm.blankDescription'));

        return;
      }

      askedCreate.value = true;
      emit('confirm', postForm.value);
    };

    const computeSlug = (name: string) => {
      if (props.isUpdating) {
        return;
      }

      postForm.value.slug =
        name &&
        name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .match(postNameForSlugMatcher)!
          .map(x => x.toLowerCase())
          .join('-');
    };

    const onNameFocusOut = (translation: TranslationUi) => {
      if (translation.language !== 'fr') {
        return;
      }

      computeSlug(translation.value);
    };

    const resetTextField = (field: PostTextFormInput) => {
      postForm.value[field] = props.formValue[field];
    };

    const resetDateField = (field: keyof DateTimeInputUi) => {
      postForm.value.date[field] = props.formValue.date[field];
    };

    const resetNameTranslation = (language: Language) => {
      translationFor(postForm.value.nameTranslations, language).value = translationFor(props.formValue.nameTranslations, language).value;
    };

    const resetDescriptionTranslation = (language: Language) => {
      translationFor(postForm.value.descriptionTranslations, language).value = translationFor(
        props.formValue.descriptionTranslations,
        language
      ).value;
    };

    const hasFieldChanged = (field: keyof PostFormUi) => postForm.value[field] !== props.formValue[field];

    const hasDateFieldChanged = (field: keyof DateTimeInputUi) => postForm.value.date[field] !== props.formValue.date[field];

    const hasNameTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.nameTranslations, translation.language).value;

    const hasDescriptionTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.descriptionTranslations, translation.language).value;

    const togglePreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedDescription: string) => {
      translation.value = updatedDescription;
    };

    const updateImage = (media: Media) => (postForm.value.imageUrl = media.url);

    const updateMedias = (medias: Media[]) => (postForm.value.medias = medias);

    const resetImageUrl = () => {
      postForm.value.imageUrl = props.formValue.imageUrl;
    };

    const translateText = async (targetLanguage: string) => {
      if (hasDescription.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchDescription = translationFor(postForm.value.descriptionTranslations, 'fr');
        const translation = await clubRepository.translateText(props.clubSlug, frenchDescription.value, 'fr', targetLanguage);
        const index = postForm.value.descriptionTranslations.findIndex(description => description.language === targetLanguage);
        postForm.value.descriptionTranslations.splice(index, 1, {
          ...postForm.value.descriptionTranslations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const hasMediasChanged = () => JSON.stringify(postForm.value.medias) !== JSON.stringify(props.formValue.medias);

    const resetMedias = () => {
      postForm.value.medias = props.formValue.medias;
    };

    const onDragStart = (event: DragEvent) => {
      if (!event || !event.dataTransfer) {
        return;
      }

      event.dataTransfer.setData('application/json', JSON.stringify(postForm.value));
      event.dataTransfer.effectAllowed = 'copy';
    };

    const onDragOver = async (event: DragEvent) => {
      if (!event || !event.dataTransfer) {
        return;
      }

      if (event.dataTransfer.types.includes('application/json')) {
        event.dataTransfer.dropEffect = 'copy';
      }
    };

    const onDrop = async (event: DragEvent) => {
      if (!event || !event.dataTransfer) {
        return;
      }

      if (event.dataTransfer.types.includes('application/json')) {
        postForm.value = JSON.parse(event.dataTransfer.getData('application/json'));
      }
    };

    const onInputMouseDown = () => {
      draggable.value = false;
    };
    const onInputMouseUp = () => {
      draggable.value = true;
    };

    return {
      t,
      mainImage,
      confirm,
      draggable,
      isLoading,
      postForm,
      programsList,
      resetImageUrl,
      translateText,
      resetMedias,
      hasMediasChanged,
      updateImage,
      updateMedias,
      onDragStart,
      onDragOver,
      onDrop,
      onInputMouseDown,
      onInputMouseUp,
      onNameFocusOut,
      resetDateField,
      resetTextField,
      hasDescription,
      hasDateFieldChanged,
      hasFieldChanged,
      isTranslationLoading,
      routeLeaveGuard,
      togglePreviewFor,
      updateTranslation,
      previewVisibleFor,
      formatDescription,
      resetNameTranslation,
      hasNameTranslationChanged,
      resetDescriptionTranslation,
      hasDescriptionTranslationChanged,
    };
  },
});
