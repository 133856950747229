import { InjectionKey } from 'vue';
import { Program } from '@/staff/domain/program/Program';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ProgramToCreate } from '@/staff/domain/program/ProgramToCreate';
import { ProgramToUpdate } from '@/staff/domain/program/ProgramToUpdate';
import { ProgramSlug } from '@/staff/domain/program/ProgramSlug';

export const programRepositoryKey: InjectionKey<ProgramRepository> = Symbol();

export interface ProgramRepository {
  list(clubSlug: ClubSlug): Promise<Program[]>;
  getBySlug(clubSlug: ClubSlug, slug: ProgramSlug): Promise<Program>;
  update(programToUpdate: ProgramToUpdate): Promise<Program>;
  create(programToCreate: ProgramToCreate): Promise<Program>;
}
