import { computed, defineComponent, PropType } from 'vue';
import { Media } from '@/common/domain/Media';
import { MediasInputVue } from '@/staff/primary/media-input';
import { MediaType } from '@/common/domain/MediaType';

export default defineComponent({
  name: 'SingleImageInput',

  components: { MediasInputVue },

  props: {
    media: {
      type: Object as PropType<Media>,
      default: undefined,
    },
    touched: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['updated-media'],

  setup(props, { emit }) {
    const medias = computed(() => (props.media ? [props.media] : []));

    const updateMedia = async (medias: Media[]) => {
      emit('updated-media', { value: medias[0] });
    };

    return {
      medias,
      MediaType,
      updateMedia,
    };
  },
});
