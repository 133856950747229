import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Router, useRouter } from 'vue-router';
import { PageVue } from '@/staff/primary/club-layout/page';
import { GiveawayParticipationsTableVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table';
import { GiveawayResultsHeaderVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-results-header';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { GiveawayExtraGiftUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayExtraGift.ui';
import { FairplayerButtonVue } from '@/common/primary/button';
import {
  fromGiveawayToClosure,
  GiveawayClosureUi,
  toGiveawayClosureToCreate,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayClosure.ui';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { Coins } from '@/common/domain/token/Coins';
import { Tokens } from '@/common/domain/token/Tokens';
import { Fiat } from '@/common/domain/token/Fiat';
import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { Loader } from '@/common/primary/loader/Loader';
import { fromGiveaway, GiveawayFormUi, toGiveawayToUpdate } from '@/staff/primary/giveaway/GiveawayForm.ui';
import { closeGiveawayConfirmationModal } from '@/common/primary/modal/Modals';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { GiveawayMissingAnswersVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-missing-answers';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { GiveawayResultsStatisticsVue } from '@/staff/primary/giveaway/giveaway-results/giveaway-results-statistics';

export default defineComponent({
  name: 'GiveawayResults',

  components: {
    GiveawayParticipationsTableVue,
    GiveawayMissingAnswersVue,
    GiveawayResultsHeaderVue,
    GiveawayResultsStatisticsVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    giveawayId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router: Router = useRouter();

    const alertBus = inject(alertBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;

    const club = clubRepository.getCurrentClub();

    const extraGiftByParticipationId: Record<string, GiveawayExtraGiftUi> = {};

    const coinsAmountByRightAnswer = ref(0);
    const isClosing = ref(false);
    const totalGifts = ref<TokensUi>();

    const giveaway = ref(Loader.loading<Giveaway>());
    const giveawayQuestions = ref(Loader.loading<GiveawayQuestionFormUi[]>());
    const giveawayForm = ref(Loader.loading<GiveawayFormUi>());
    const missingAnswers = ref(Loader.loading<GiveawayQuestionFormUi[]>());
    const giveawayClosure = ref(Loader.loading<GiveawayClosureUi>());

    onMounted(() => loadGiveaway());

    const loadGiveaway = () => {
      giveawayForm.value = Loader.loading<GiveawayFormUi>();
      clubRepository.getGiveaway(props.clubSlug, props.giveawayId).then(retrievedGiveaway => {
        const giveawayFormUi = fromGiveaway(retrievedGiveaway, props.clubSlug, t);

        giveaway.value.loaded(retrievedGiveaway);
        giveawayForm.value.loaded(giveawayFormUi);
        giveawayQuestions.value.loaded(giveawayFormUi.questions);
        missingAnswers.value.loaded(giveawayFormUi.questions.filter(question => !question.rightAnswer && question.type === 'CHOICE'));
        giveawayClosure.value.loaded(fromGiveawayToClosure(retrievedGiveaway, club.coin));
      });
    };

    const updateMissingAnswers = async (giveawayWithRightAnswers: GiveawayQuestionFormUi[]) => {
      giveawayWithRightAnswers.forEach(question => {
        giveawayForm.value.value().questions.find(q => q.id === question.id)!.rightAnswer = question.rightAnswer;
      });
      const giveawayToUpdate = toGiveawayToUpdate(giveawayForm.value.value(), giveaway.value.value());

      return clubRepository.updateGiveaway(giveawayToUpdate).then(() => {
        modalBus.close();
        loadGiveaway();
      });
    };

    const coinsAmountByRightAnswerUpdated = (coinsAmount: number) => {
      coinsAmountByRightAnswer.value = coinsAmount;
    };

    const extraGiftUpdated = (extraGift: GiveawayExtraGiftUi) => {
      extraGiftByParticipationId[extraGift.participationId] = extraGift;
    };

    const totalGiftsUpdated = (total: TokensUi) => {
      totalGifts.value = total;
    };

    const openCloseWithoutGiftConfirmation = () => {
      const modal = closeGiveawayConfirmationModal();
      modalBus.open({
        component: modal,
        options: {
          confirmAction: closeNoGift,
          bodyTranslationKey: 'giveawayResults.closeConfirmation.withoutGift',
        },
      });
    };

    const openCloseWithGiftsConfirmation = () => {
      const modal = closeGiveawayConfirmationModal();
      const translationVariables = { coinsText: totalGifts.value!.coins.text, fiatText: totalGifts.value!.totalCost.text };
      modalBus.open({
        component: modal,
        options: {
          confirmAction: closeWithGifts,
          bodyTranslationKey: 'giveawayResults.closeConfirmation.withGifts',
          bodyTranslationVariables: translationVariables,
        },
      });
    };

    const closeNoGift = async (): Promise<void> => close(0, []);

    const closeWithGifts = async (): Promise<void> => {
      const extraGifts: GiveawayExtraGiftUi[] = Object.values(extraGiftByParticipationId).filter(
        extraGift => extraGift.gift.coins.amount > 0
      );

      await close(coinsAmountByRightAnswer.value, extraGifts);
    };

    const close = async (coinsAmountByRightAnswer: number, extraGifts: GiveawayExtraGiftUi[]) => {
      const { clubSlug, giveawayId } = props;

      isClosing.value = true;

      const giftByRightAnswer = fromTokens(
        Tokens.of(new Coins(coinsAmountByRightAnswer, club.coin.ticker)).withTokenCost(Fiat.euro(club.coin.price))
      );

      const closureToCreate: GiveawayClosureUi = {
        giftByRightAnswer,
        extraGifts,
        closable: false,
      };
      await clubRepository.closeGiveaway(toGiveawayClosureToCreate(clubSlug, giveawayId, closureToCreate));
      alertBus.alert('toasts.success.giveaway.closure', 'success');
      giveawayClosure.value.loaded(closureToCreate);
      isClosing.value = false;
    };

    const onGoBack = async (): Promise<void> => {
      await router.push({ name: 'giveaways', params: { clubSlug: props.clubSlug } });
    };

    return {
      openCloseWithoutGiftConfirmation,
      openCloseWithGiftsConfirmation,
      closeNoGift,
      closeWithGifts,
      coinsAmountByRightAnswerUpdated,
      giveawayForm,
      giveawayQuestions,
      missingAnswers,
      giveawayClosure,
      updateMissingAnswers,
      extraGiftUpdated,
      totalGiftsUpdated,
      isClosing,
      onGoBack,
      t,
    };
  },
});
