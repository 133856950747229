import {
  confirmResetPassword,
  ConfirmResetPasswordInput,
  confirmSignUp,
  ConfirmSignUpInput,
  fetchAuthSession,
  resendSignUpCode,
  ResendSignUpCodeInput,
  resetPassword,
  ResetPasswordInput,
  signIn,
  SignInInput,
  signInWithRedirect,
  SignInWithRedirectInput,
  signOut,
  signUp,
  SignUpInput,
} from '@aws-amplify/auth';
import { AuthSession } from '@aws-amplify/core/src/singleton/Auth/types';
import { SignInWithUserPasswordOutput } from '@aws-amplify/auth/dist/esm/providers/cognito/types';

export class CognitoClient {
  async signInWithRedirect(options?: SignInWithRedirectInput): Promise<void> {
    return signInWithRedirect(options);
  }

  async signInWithUserPassword(signInInput: SignInInput): Promise<SignInWithUserPasswordOutput> {
    return signIn(signInInput);
  }

  async signUp(signUpInput: SignUpInput): Promise<void> {
    await signUp(signUpInput);
  }

  async confirmSignUp(confirmSignUpInput: ConfirmSignUpInput): Promise<void> {
    await confirmSignUp(confirmSignUpInput);
  }

  async resendSignUpCode(resendSignUpCodeInput: ResendSignUpCodeInput): Promise<void> {
    await resendSignUpCode(resendSignUpCodeInput);
  }

  async signOut(): Promise<void> {
    return signOut();
  }

  async resetPassword(resetPasswordInput: ResetPasswordInput): Promise<void> {
    await resetPassword(resetPasswordInput);
  }

  async confirmResetPassword(confirmResetPasswordInput: ConfirmResetPasswordInput): Promise<void> {
    return confirmResetPassword(confirmResetPasswordInput);
  }

  async fetchAuthSession(): Promise<AuthSession> {
    return fetchAuthSession();
  }
}
