import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { AlertType } from '@/common/domain/alert/AlertType';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { ToastVue } from '@/staff/primary/toasts/toast';
import { ToastUi } from '@/staff/primary/toasts/Toast.ui';
import { inject, onMounted, onUnmounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ToastsList',
  components: { ToastVue },

  setup() {
    const alertBus = inject(alertBusKey)!;
    const { t } = useI18n();

    const toasts: Ref<ToastUi[]> = ref([]);
    const id = ref(0);
    let unsubscribeAlertBus: Unsubscribe;

    const generateId = (): number => {
      const newId = id.value;
      id.value++;
      return newId;
    };

    const display = (message: string, type: AlertType) => {
      const id = generateId();
      toasts.value.push({ id, type, message: t(message) });
    };

    const remove = (id: number) => {
      toasts.value = toasts.value.filter(entry => entry.id !== id);
    };

    onMounted(() => {
      unsubscribeAlertBus = alertBus.onAlert((alertMessage, type) => display(alertMessage, type));
    });

    onUnmounted(() => {
      unsubscribeAlertBus();
    });

    return { toasts, remove, display };
  },
};
