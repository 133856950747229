import { Member } from '@/staff/domain/fan/Member';

export interface MemberUi {
  id: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
}

export const fromMember = (member: Member): MemberUi => ({
  id: member.id,
  pictureUrl: member.pictureUrl,
  firstName: member.firstName,
  lastName: member.lastName,
});
