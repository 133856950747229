import { OldFan } from '@/staff/domain/old-fan/OldFan';

export interface BackendOldFan {
  username: string;
  email: string;
}

export const toOldFan = (backendFan: BackendOldFan): OldFan => ({
  username: backendFan.username,
  email: backendFan.email,
});
