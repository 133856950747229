import { marked, Tokens } from 'marked';
import DOMPurify from 'dompurify';

const renderer = new marked.Renderer();

renderer.link = (link: Tokens.Link) => `<a target="_blank" href="${link.href}">${link.text}</a>`;

marked.use({ renderer });

export const renderMarkdown = (input: string) => DOMPurify.sanitize(marked(input) as string, { ADD_ATTR: ['target'] });
