export interface NotFoundUi {
  buttonKey: string;
  routeName: string;
  titleKey: string;
}

export const notFoundUi = () => ({
  buttonKey: 'notFound.goToHomepage',
  routeName: 'home',
  titleKey: 'notFound.title',
});

export const clubNotFoundUi = () => ({
  buttonKey: 'notFound.goToClubSelection',
  routeName: 'clubsPage',
  titleKey: 'notFound.clubNotFound',
});
