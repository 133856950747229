import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import {
  BackendGiveawayQuestionToUpdate,
  toBackendGiveawayQuestionToUpdate,
} from '@/staff/secondary/club/giveaway/BackendGiveawayQuestionToUpdate';

export interface BackendGiveawayToUpdate {
  url?: string;
  startDate?: string;
  endDate?: string;
  questions?: BackendGiveawayQuestionToUpdate[];
  partnerSlug?: string;
}

export const toBackendGiveawayToUpdate = (giveawayToUpdate: GiveawayToUpdate): BackendGiveawayToUpdate => ({
  url: giveawayToUpdate.url.orElseUndefined(),
  startDate: giveawayToUpdate.startDate.map(date => date.toISOString()).orElseUndefined(),
  endDate: giveawayToUpdate.endDate.map(date => date.toISOString()).orElseUndefined(),
  questions: giveawayToUpdate.questions.map(q => q.map(toBackendGiveawayQuestionToUpdate)).orElseUndefined(),
  partnerSlug: giveawayToUpdate.partner.orElseUndefined(),
});
