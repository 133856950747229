import { Program } from '@/staff/domain/program/Program';
import { BackendTranslation, toTranslation } from '@/common/secondary/BackendTranslation';
import { ProgramName } from '@/staff/domain/program/ProgramName';
import { ProgramDescription } from '@/staff/domain/program/ProgramDescription';

export interface BackendProgram {
  id: string;
  slug: string;
  imageUrl: string;
  name: string;
  description: string;
  nameTranslations?: BackendTranslation[];
  descriptionTranslations?: BackendTranslation[];
}

export const toProgram = (backendProgram: BackendProgram): Program => ({
  id: backendProgram.id,
  slug: backendProgram.slug,
  imageUrl: backendProgram.imageUrl,
  name: backendProgram.name,
  description: backendProgram.description,
  nameTranslations: backendProgram.nameTranslations?.map(toTranslation<ProgramName>),
  descriptionTranslations: backendProgram.descriptionTranslations?.map(toTranslation<ProgramDescription>),
});
