import { GiveawayAnswerToUpdate } from '@/staff/domain/club/giveaway/GiveawayAnswerToUpdate';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { GiveawayAnswerText } from '@/staff/domain/club/giveaway/GiveawayAnswerText';

export interface BackendGiveawayAnswerToUpdate {
  id?: string;
  textTranslations: BackendTranslation[];
}

export const toBackendGiveawayAnswerToUpdate = (giveawayAnswerToUpdate: GiveawayAnswerToUpdate): BackendGiveawayAnswerToUpdate => ({
  id: giveawayAnswerToUpdate.id.map(id => id).orElseUndefined(),
  textTranslations: [fromTranslation<GiveawayAnswerText>({ language: 'fr', value: giveawayAnswerToUpdate.text })],
});
