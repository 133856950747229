import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';

export const giveawayParticipationRepositoryKey: InjectionKey<GiveawayParticipationRepository> = Symbol();

export interface GiveawayParticipationRepository {
  listGiveawayParticipations(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayParticipation[]>;
  getQuestionsStatistics(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<GiveawayQuestionStatistics[]>;
}
