import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { GlobalStatistics } from '@/staff/domain/club/statistics/GlobalStatistics';
import { InjectionKey } from 'vue';
import { Coin } from '@/common/domain/token/Coin';

export const clubStatisticsRepositoryKey: InjectionKey<ClubStatisticsRepository> = Symbol();

export interface ClubStatisticsRepository {
  getSalesAmount(clubSlug: ClubSlug, coin: Coin): Promise<GlobalStatistics>;
}
