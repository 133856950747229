import { CoinsGifts } from '@/staff/domain/club/CoinsGifts';
import { CoinsGiftUi, fromCoinsGift } from '@/staff/primary/club/coins-gifts-table/CoinsGift.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface CoinsGiftsUi {
  coinGifts: CoinsGiftUi[];
  totalTokens: TokensUi;
  totalNotExpiredTokens: TokensUi;
}

export const fromCoinsGifts = (coinsGifts: CoinsGifts): CoinsGiftsUi => ({
  coinGifts: coinsGifts.gifts.map(fromCoinsGift),
  totalTokens: fromTokens(coinsGifts.totalTokens()),
  totalNotExpiredTokens: fromTokens(coinsGifts.totalNotExpiredTokens()),
});

export const emptyCoinsGifts = (): CoinsGiftsUi => ({
  coinGifts: [],
  totalTokens: emptyTokensUi(),
  totalNotExpiredTokens: emptyTokensUi(),
});
