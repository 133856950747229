import { defineComponent } from 'vue';
import { PageVue } from '@/staff/primary/club-layout/page';
import { useI18n } from 'vue-i18n';
import { FansTableVue } from '@/staff/primary/fan/fans-table';

export default defineComponent({
  name: 'FansPage',

  components: {
    FansTableVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
