import { Loader } from '@/common/primary/loader/Loader';
import { clubStatisticsRepositoryKey } from '@/staff/domain/club/statistics/ClubStatisticsRepository';
import { fromGlobalStatistics, GlobalStatisticsUi } from '@/staff/primary/club/statistics/global-statistics/GlobalStatistics.ui';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { FansStatisticsVue } from '@/staff/primary/club/statistics/fans-statistics';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'GlobalStatistics',

  components: {
    FansStatisticsVue,
  },

  props: {
    showStatistics: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const clubStatisticsRepository = inject(clubStatisticsRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const globalStatistics = ref(Loader.loading<GlobalStatisticsUi>());

    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(() => {
      if (props.showStatistics) {
        getSalesAmount();
      }
    });

    const getSalesAmount = () => {
      clubStatisticsRepository
        .getSalesAmount(club.slug, club.coin)
        .then(globalStatisticsRepository => globalStatistics.value.loaded(fromGlobalStatistics(globalStatisticsRepository)))
        .catch(error => {
          logger.error('Failed to retrieve global statistics', error);
        });
    };

    return {
      club,
      globalStatistics,
      t,
    };
  },
});
