import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import {
  BackendGiveawayQuestionToCreate,
  toBackendGiveawayQuestionToCreate,
} from '@/staff/secondary/club/giveaway/BackendGiveawayQuestionToCreate';

export interface BackendGiveawayToCreate {
  url: string;
  startDate: string;
  endDate: string;
  questions: BackendGiveawayQuestionToCreate[];
  partnerSlug?: string;
}

export const toBackendGiveawayToCreate = (giveawayToCreate: GiveawayToCreate): BackendGiveawayToCreate => ({
  url: giveawayToCreate.url,
  startDate: giveawayToCreate.startDate.toISOString(),
  endDate: giveawayToCreate.endDate.toISOString(),
  questions: giveawayToCreate.questions.map(toBackendGiveawayQuestionToCreate),
  partnerSlug: giveawayToCreate.partner.orElseUndefined(),
});
