include ../../club/loading-table.mixin.pug

template(v-if='donations.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasDonations' data-test='no-donations') {{ t('home.donations.noDonations') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='donations-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.table.fan') }}
            th {{ t('home.donations.table.amount') }}
        tbody
          tr(v-for='(donation, index) in donations.value().donations' :key='index' :data-test='`donation-${index}`')
            td
              code {{ donation.date.toHuman() }}
            td(data-test='email') {{ donation.fanEmail }}
            td {{ donation.amount.text }}
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        span.fp-text.fp-text {{ t('home.donations.totalAmount') }} &nbsp;
          span.fp-text.fp-text.-color-shade-900.-text-semi-bold(data-test='total-donations') {{ donations.value().total.text }}
