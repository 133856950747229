import { Alert } from '@/common/domain/alert/Alert';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { Alerted } from '@/common/domain/alert/Alerted';
import { AlertMessage } from '@/common/domain/alert/AlertMessage';
import { AlertType } from '@/common/domain/alert/AlertType';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { Emitter, Handler } from 'mitt';

export class MittAlertBus implements AlertBus {
  constructor(private emitter: Emitter<any>) {}

  alert(alertMessage: AlertMessage, alertType: AlertType): void {
    this.emitter.emit('alert', {
      type: alertType,
      message: alertMessage,
    });
  }

  onAlert(alerted: Alerted): Unsubscribe {
    const handler: Handler<Alert> = alert => alerted(alert.message, alert.type);
    this.emitter.on<AlertMessage>('alert', handler);
    return () => this.emitter.off('alert', handler);
  }
}
