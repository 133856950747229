import { inject, onMounted, onUnmounted, ref, useSlots } from 'vue';
import { clickBusKey } from '@/common/domain/click/ClickBus';
import { Unsubscribe } from '@/common/domain/Unsubscribe';

export default {
  name: 'Dropdown',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  setup() {
    const slots = useSlots();
    const clickBus = inject(clickBusKey)!;

    const opened = ref(false);

    let unsubscribeClickBus: Unsubscribe;
    const trigger = ref<HTMLElement>();

    const close = (mouseEvent: MouseEvent) => {
      if (mouseEvent.target && !trigger.value?.contains(mouseEvent.target as Node)) {
        opened.value = false;
      }
    };

    onMounted(() => {
      unsubscribeClickBus = clickBus.onClick(mouseEvent => close(mouseEvent));
    });

    onUnmounted(() => {
      unsubscribeClickBus();
    });

    return {
      trigger,
      opened,
      slots,
    };
  },
};
