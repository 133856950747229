import { computed, ComputedRef, defineComponent, inject, PropType } from 'vue';
import { Bar } from 'vue-chartjs';
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, ChartOptions, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useI18n } from 'vue-i18n';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { loggerKey } from '@/common/domain/Logger';
import { GiveawayQuestionStatisticsUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-results-statistics/giveaway-results-chart/GiveawayQuestionStatistics.ui';

export default defineComponent({
  name: 'GiveawayResultsChart',

  components: { Bar },

  props: {
    questionsStatistics: {
      type: Array as PropType<GiveawayQuestionStatisticsUi[]>,
      required: true,
    },
    questions: {
      type: Array as PropType<GiveawayQuestionFormUi[]>,
      required: true,
    },
    selectedQuestion: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

    const { t } = useI18n();
    const logger = inject(loggerKey)!;

    const currentStatistics: ComputedRef<GiveawayQuestionStatisticsUi | undefined> = computed(() =>
      (props.questionsStatistics as GiveawayQuestionStatisticsUi[]).find(
        question => (props.questions as GiveawayQuestionFormUi[])[props.selectedQuestion].id === question.id
      )
    );

    if (!currentStatistics.value) {
      logger.error('Failed to match statistics with question');
    }

    const chartData: ComputedRef<ChartData> = computed(() => {
      const makeChartData = (labels?: string[], data?: number[]): ChartData => ({
        labels: labels || [],
        datasets: [
          {
            backgroundColor: '#34c759',
            data: data || [],
          },
        ],
      });

      if (!currentStatistics.value) {
        return makeChartData();
      }

      const labels = currentStatistics.value.answers.map(answer => `${answer.text} ${answer.mark}`.trim());
      const data = currentStatistics.value.answers.map(answer => answer.count);

      if (currentStatistics.value.noAnswerCount > 0) {
        labels.push(t('giveawayResults.participations.noAnswer'));
        data.push(currentStatistics.value.noAnswerCount);
      }

      return makeChartData(labels, data);
    });

    const chartOptions: ChartOptions = {
      plugins: { legend: { display: false } },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
            precision: 0,
          },
        },
      },
    };

    return {
      currentStatistics,
      chartData,
      chartOptions,
    };
  },
});
