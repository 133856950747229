import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { postRepositoryKey } from '@/staff/domain/post/PostRepository';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { Loader } from '@/common/primary/loader/Loader';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { PostFormVue } from '@/staff/primary/post/post-form';
import { emptyPostForm, PostFormUi, toPostToCreate } from '@/staff/primary/post/post-form/PostForm.ui';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CreatePost',

  components: {
    FairplayerButtonVue,
    PostFormVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const alertBus = inject(alertBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const postRepository = inject(postRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const postForm = ref(Loader.loading<PostFormUi>());

    onMounted(async () => postForm.value.loaded(emptyPostForm(club.languages)));

    const createPost = async (postForm: PostFormUi): Promise<void> => {
      return postRepository
        .create(toPostToCreate(postForm, props.clubSlug))
        .then(post => goToCreatedPost(post.slug))
        .catch(error => logger.error('Failed to create post', error));
    };

    const goToCreatedPost = (slug: string) => {
      alertBus.alert('toasts.success.post.creation', 'success');

      router.push({ name: 'updatePost', params: { clubSlug: props.clubSlug, postSlug: slug } });
    };

    const onGoBack = () => {
      router.push({ name: 'posts', params: { clubSlug: props.clubSlug } });
    };

    return {
      createPost,
      postForm,
      onGoBack,
      t,
    };
  },
});
