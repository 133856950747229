import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { GiveawayParticipationUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipation.ui';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { Fiat } from '@/common/domain/token/Fiat';

export interface GiveawayParticipationResultUi {
  rightAnswersGift: TokensUi;
  extraGift: TokensUi;
  totalGifts: TokensUi;
}

const computeTokensUiFor = (coinsAmount: number, coin: Coin) =>
  fromTokens(Tokens.of(new Coins(coinsAmount, coin.ticker)).withTokenCost(Fiat.euro(coin.price)));

export const fromParticipationAndExtraGift = (
  participation: GiveawayParticipationUi,
  rightAnswerCoinsAmount: number,
  extraGiftCoinsAmount: number,
  coin: Coin
): GiveawayParticipationResultUi => {
  const rightAnswersCoinsAmount = participation.numberOfRightAnswers * rightAnswerCoinsAmount;
  const totalGiftsCoinsAmount = rightAnswersCoinsAmount + extraGiftCoinsAmount;

  return {
    rightAnswersGift: computeTokensUiFor(rightAnswersCoinsAmount, coin),
    extraGift: computeTokensUiFor(extraGiftCoinsAmount, coin),
    totalGifts: computeTokensUiFor(totalGiftsCoinsAmount, coin),
  };
};
