import { CoinsGift } from '@/staff/domain/club/CoinsGift';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { DateUi } from '@/common/primary/date/Date.ui';
import { CoinsGiftStatus } from '@/staff/domain/club/CoinsGiftStatus';

export const PENDING_ICON_CLASS = 'fp-glyph-hourglass-low -color-warning';
export const DELIVERED_ICON_CLASS = 'fp-glyph-check -color-success';
export const EXPIRED_ICON_CLASS = 'fp-glyph-calendar-x -color-danger';

type IconClass = typeof DELIVERED_ICON_CLASS | typeof PENDING_ICON_CLASS | typeof EXPIRED_ICON_CLASS;

export interface CoinsGiftUi {
  amount: TokensUi;
  fanEmail: string;
  date: DateUi;
  status: CoinsGiftStatus;
  iconClass: IconClass;
}

const iconByStatus: Record<CoinsGiftStatus, IconClass> = {
  [CoinsGiftStatus.PENDING]: PENDING_ICON_CLASS,
  [CoinsGiftStatus.DELIVERED]: DELIVERED_ICON_CLASS,
  [CoinsGiftStatus.EXPIRED]: EXPIRED_ICON_CLASS,
};

export const fromCoinsGift = (coinsGift: CoinsGift): CoinsGiftUi => ({
  amount: fromTokens(coinsGift.coins),
  fanEmail: coinsGift.fanEmail,
  date: new DateUi(coinsGift.date),
  status: coinsGift.status,
  iconClass: iconByStatus[coinsGift.status],
});
