import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { CoinsGift } from '@/staff/domain/club/CoinsGift';
import { CoinsGiftStatus } from '@/staff/domain/club/CoinsGiftStatus';

export interface BackendCoinsGift {
  id: string;
  coins: BackendPrice;
  fanEmail: string;
  date: string;
  status: CoinsGiftStatus;
}

export const toCoinsGift = (backendCoinsGift: BackendCoinsGift): CoinsGift => ({
  id: backendCoinsGift.id,
  coins: toTokens(backendCoinsGift.coins),
  fanEmail: backendCoinsGift.fanEmail,
  date: new Date(backendCoinsGift.date),
  status: backendCoinsGift.status,
});

export const sortCoinsGiftsByDateDesc = (coinsGift1: CoinsGift, coinsGift2: CoinsGift) =>
  coinsGift2.date.getTime() - coinsGift1.date.getTime();
