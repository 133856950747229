import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { GiveawayParticipationUi } from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipation.ui';

export default defineComponent({
  name: 'GiveawayParticipationAnswers',

  props: {
    giveawayParticipation: {
      type: Object as PropType<GiveawayParticipationUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
