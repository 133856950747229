import {
  BackendGiveawayAnswersStatistics,
  toGiveawayAnswersStatistics,
} from '@/staff/secondary/club/giveaway/BackendGiveawayAnswersStatistics';
import { GiveawayQuestionStatistics } from '@/staff/domain/club/giveaway/GiveawayQuestionStatistics';
import { QuizMode } from '@/staff/domain/club/giveaway/QuizMode';

export interface BackendGiveawayQuestionStatistics {
  id: string;
  noAnswerCount: number;
  totalAnswerCount: number;
  quizMode: QuizMode;
  answers: Array<BackendGiveawayAnswersStatistics>;
}

export const toGiveawayQuestionStatistics = (
  backendGiveawayQuestionStatistics: BackendGiveawayQuestionStatistics
): GiveawayQuestionStatistics => ({
  id: backendGiveawayQuestionStatistics.id,
  noAnswerCount: backendGiveawayQuestionStatistics.noAnswerCount,
  totalAnswerCount: backendGiveawayQuestionStatistics.totalAnswerCount,
  quizMode: backendGiveawayQuestionStatistics.quizMode,
  answers: backendGiveawayQuestionStatistics.answers.map(toGiveawayAnswersStatistics),
});
