include ../../club/loading-table.mixin.pug

template(v-if='orders.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasOrders' data-test='no-orders')
    span(v-if='club.isMarketplace' data-test='no-orders-title') {{ t('home.orders.noOrders') }}
    span(v-else data-test='no-orders-title-foundation') {{ t('home.orders.noOrdersFoundation') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column
          .fp-checkable-element
            .fp-checkable-element--checkbox
              input.fp-checkbox#delivered-checkbox(v-model='showDeliveredOrders' type='checkbox' data-test='delivered-checkbox')
            .fp-checkable-element--label
              label(for='delivered-checkbox')
                span(v-if='club.isMarketplace' data-test='show-delivered-title') {{ t('home.orders.showDeliveredOrders') }} &nbsp;
                span(v-else data-test='show-delivered-title-foundation') {{ t('home.orders.showDeliveredOrdersFoundation') }} &nbsp;
    .fp-vertical-spacing--line
      p(v-if='!filteredOrders.length' data-test='no-filtered-orders') {{ t('home.orders.noResults') }}
      table.fp-table(v-else data-test='orders-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.table.fan') }}
            th {{ t('home.orders.table.exclusive') }}
            th {{ t('home.orders.table.totalCost') }}
            th(v-if='club.fiatAccepted && club.coinsEnabled' data-test='final-cost-header') {{ t('home.orders.table.finalCost') }}
            th(v-else-if='club.coinsEnabled' data-test='cost-coins-header') {{ t('home.orders.table.tokensAmount') }}
            th.-shrink {{ t('home.orders.table.deliveryStatus') }}
        tbody
          tr(v-for='(order, index) in filteredOrders' :key='index' :data-test='`order-${index}`')
            td(:title='order.date.toHuman()')
              RelativeTimeVue(:date='order.date.date')
            td {{ order.fan.email }}
            td(:title='order.exclusives[0].name' data-test='slug')
              .fp-horizontal-spacing.-items-center.-s-md
                .fp-horizontal-spacing--column
                  .fp-image.-rounded
                    img(:src='order.exclusives[0].imageUrl' alt='Exclusive image')
                .fp-horizontal-spacing--column  {{ order.exclusives[0].slug }}
            td {{ order.originalCost.totalCost.text }}
            td(v-if='club.fiatAccepted && club.coinsEnabled' data-test='final-cost') {{ order.finalCost.totalCost.text }}
            td(v-else-if='club.coinsEnabled' data-test='cost-coins') {{ order.originalCost.coins.text }}
            td
              i.fp-icon.fp-glyph-loader.-spin.-s18(v-if='order.loading' data-test='loading')
              input.fp-checkbox(v-else :checked='order.delivered' @click='deliver(order, $event)' type='checkbox' :disabled='order.delivered' :title='t(order.delivered ? "home.orders.delivered": "home.orders.deliver")' data-test='delivery-status')
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        .fp-text
          ul(v-if='club.fiatAccepted && club.coinsEnabled')
            li(data-test='total-used-coins')
              span.fp-text {{ t('home.orders.totalUsedCoins') }} &nbsp;
              ExclusivePriceVue(:pricing='orders.value().totalUsedCoins')
            li(data-test='total-final-cost')
              span.fp-text {{ t('home.orders.totalFinalCost') }} &nbsp;
              ExclusivePriceVue(:pricing='orders.value().totalFinalCosts')
          span.fp-text
            span(v-if='club.isMarketplace' data-test='total-amount-title') {{ t('home.orders.totalAmount') }} &nbsp;
            span(v-else data-test='total-amount-title-foundation') {{ t('home.orders.totalAmountFoundation') }} &nbsp;
            ExclusivePriceVue(:pricing='orders.value().totalTokens' data-test='total-orders')
