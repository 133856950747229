import { defineComponent, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { emptyAnswerUi, GiveawayAnswerUi } from '@/staff/primary/giveaway/GiveawayAnswer.ui';
import { GiveawayQuestionTypeUi } from '@/staff/primary/giveaway/create-giveaway/GiveawayQuestionType.ui';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { emptyMarkdownTextUi, MarkdownTextUi } from '@/common/primary/markdown/MarkdownText.ui';

const MINIMAL_QUESTION_ANSWERS = 2;

export default defineComponent({
  name: 'GiveawayFormQuestion',

  components: {
    MarkdownEditorVue,
  },

  props: {
    question: {
      type: Object as PropType<GiveawayQuestionFormUi>,
      required: true,
    },

    questionIndex: {
      type: Number as PropType<number>,
      required: true,
    },

    isLastQuestion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    isLastAnswer: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    limitedUpdate: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['question-updated', 'remove-question', 'up', 'down', 'input-mouse-up', 'input-mouse-down', 'clone-question'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const questionToUpdate = ref(props.question);
    const isPreTitleShown = ref(questionToUpdate.value.preTitle.value !== '');
    const questionTypes = Object.keys(GiveawayQuestionTypeUi);

    const computePreTitle = (): MarkdownTextUi => {
      if (!isPreTitleShown.value) {
        return emptyMarkdownTextUi();
      }
      return questionToUpdate.value.preTitle;
    };

    const computeMinimalAnswersIfMissing = (): GiveawayAnswerUi[] => {
      if (
        questionToUpdate.value.type === GiveawayQuestionTypeUi.CHOICE &&
        questionToUpdate.value.answers.length < MINIMAL_QUESTION_ANSWERS
      ) {
        questionToUpdate.value.answers = [emptyAnswerUi(), emptyAnswerUi()];
      }
      return questionToUpdate.value.answers;
    };

    watch(
      () => [questionToUpdate, isPreTitleShown],
      () =>
        emit('question-updated', {
          question: { ...questionToUpdate.value, preTitle: computePreTitle(), answers: computeMinimalAnswersIfMissing() },
          index: props.questionIndex,
        }),
      { deep: true }
    );

    const addAnswer = (): void => {
      questionToUpdate.value.answers = [...props.question.answers, emptyAnswerUi()];
    };

    const removeAnswer = (answerIdx: number): void => {
      const questionToUpdateAnswers = props.question.answers.filter((answer, idx) => idx !== answerIdx);
      if (!questionToUpdateAnswers.find(answer => answer.text == props.question.rightAnswer?.text)) {
        questionToUpdate.value.rightAnswer = props.question.answers[0];
      }
      questionToUpdate.value.answers = questionToUpdateAnswers;
    };

    const cloneQuestion = () => {
      emit('clone-question');
    };

    const removeQuestion = () => {
      emit('remove-question');
    };

    const moveQuestionUp = () => {
      emit('up');
    };

    const moveQuestionDown = () => {
      emit('down');
    };

    const moveAnswerUp = (answerIdx: number) => {
      const previousAnswer = questionToUpdate.value.answers[answerIdx - 1];
      questionToUpdate.value.answers[answerIdx - 1] = questionToUpdate.value.answers[answerIdx];
      questionToUpdate.value.answers[answerIdx] = previousAnswer;
    };

    const moveAnswerDown = (answerIdx: number) => {
      const nextAnswer = questionToUpdate.value.answers[answerIdx + 1];
      questionToUpdate.value.answers[answerIdx + 1] = questionToUpdate.value.answers[answerIdx];
      questionToUpdate.value.answers[answerIdx] = nextAnswer;
    };

    const updatePreTitle = (updatedPreTitle: MarkdownTextUi) => {
      questionToUpdate.value.preTitle = updatedPreTitle;
    };

    const showPreTitle = () => {
      isPreTitleShown.value = true;
    };

    const hidePreTitle = () => {
      isPreTitleShown.value = false;
    };

    const onInputMouseDown = () => {
      emit('input-mouse-down');
    };
    const onInputMouseUp = () => {
      emit('input-mouse-up');
    };

    return {
      t,
      showPreTitle,
      cloneQuestion,
      removeQuestion,
      removeAnswer,
      addAnswer,
      questionTypes,
      questionToUpdate,
      isPreTitleShown,
      hidePreTitle,
      updatePreTitle,
      MINIMAL_QUESTION_ANSWERS,
      moveQuestionUp,
      moveQuestionDown,
      moveAnswerUp,
      moveAnswerDown,
      onInputMouseDown,
      onInputMouseUp,
    };
  },
});
