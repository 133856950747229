import { GiveawayParticipation } from '@/staff/domain/club/giveaway/GiveawayParticipation';
import { DateUi } from '@/common/primary/date/Date.ui';
import {
  fromGiveawayParticipationAnsweredQuestion,
  GiveawayParticipationAnsweredQuestionUi,
} from '@/staff/primary/giveaway/giveaway-results/giveaway-participations-table/GiveawayParticipationAnsweredQuestion.ui';

export interface GiveawayParticipationUi {
  id: string;
  email: string;
  numberOfRightAnswers: number;
  date: string;
  answeredQuestions: GiveawayParticipationAnsweredQuestionUi[];
}

export const fromGiveawayParticipation = (giveawayParticipation: GiveawayParticipation): GiveawayParticipationUi => ({
  id: giveawayParticipation.id,
  email: giveawayParticipation.email,
  numberOfRightAnswers: giveawayParticipation.numberOfRightAnswers,
  date: new DateUi(giveawayParticipation.date).toHuman(),
  answeredQuestions: giveawayParticipation.answeredQuestions.map(fromGiveawayParticipationAnsweredQuestion),
});
