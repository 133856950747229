import { computed, defineComponent, PropType, ref } from 'vue';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';

export default defineComponent({
  name: 'FairplayerImage',

  props: {
    url: {
      type: String as PropType<string>,
      default: '',
      required: false,
    },
    fallbackImage: {
      type: String as PropType<FairplayerFallbackImageUi>,
      default: FairplayerFallbackImageUi.EXCLUSIVE,
    },
  },

  setup(props) {
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;
    const imageNotFound = ref(!props.url);

    const fallbackImageName = computed((): string => {
      if (props.fallbackImage === FairplayerFallbackImageUi.FAN) {
        return 'fan.jpg';
      }

      return 'exclusive.jpg';
    });

    const onNotFoundImage = () => {
      imageNotFound.value = true;
    };

    return {
      fallbackImageName,
      imageNotFound,
      onNotFoundImage,
      staticFilesUri,
    };
  },
});
