import { AnsweredQuestionResult } from '@/staff/domain/club/giveaway/AnsweredQuestionResult';
import { GiveawayParticipationAnsweredQuestion } from '@/staff/domain/club/giveaway/GiveawayParticipationAnsweredQuestion';
import { Optional } from '@/common/domain/Optional';

export interface BackendGiveawayParticipationAnsweredQuestion {
  questionTitle: string;
  result: AnsweredQuestionResult;
  answerText?: string;
}

export const toGiveawayParticipationAnsweredQuestion = (
  question: BackendGiveawayParticipationAnsweredQuestion
): GiveawayParticipationAnsweredQuestion => ({
  questionTitle: question.questionTitle,
  answerText: Optional.ofUndefinable(question.answerText),
  result: question.result,
});
