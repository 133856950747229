import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { missingAnswersFormModal } from '@/common/primary/modal/Modals';
import { GiveawayQuestionFormUi } from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';

export default defineComponent({
  name: 'GiveawayMissingAnswers',

  components: { FairplayerButtonVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    missingAnswers: {
      type: Object as PropType<GiveawayQuestionFormUi[]>,
      required: true,
    },
  },

  emits: ['missing-answers-filled'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const modalBus = inject(modalBusKey)!;

    const confirmAction = (questions: GiveawayQuestionFormUi[]) => emit('missing-answers-filled', { value: questions });

    const openMissingAnswersFormModal = () => {
      const modal = missingAnswersFormModal();
      modalBus.open({
        component: modal,
        options: {
          missingAnswers: props.missingAnswers,
          confirmAction,
        },
      });
    };

    return {
      t,
      confirmAction,
      openMissingAnswersFormModal,
    };
  },
});
