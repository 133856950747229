PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-program.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('programForm.updateTitle') }}
    .fp-vertical-spacing--line(v-if='!programForm.isLoading()')
      ProgramFormVue#update-program-form(:club-slug='clubSlug' :is-updating='true' :form-value='programForm.value()' @confirm='onConfirmUpdate')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-program.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'programForm.updateProgram\')' type='submit' form='update-program-form' icon='edit' data-test='update-program.submit')
