import { Authentication } from '@/common/domain/auth/Authentication';
import { RouteLocationNormalized } from 'vue-router';
import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';

export const authenticationGuard =
  (authentication: Authentication, loginContextRepository: LoginContextRepository) =>
  async (to: RouteLocationNormalized): Promise<void> => {
    if (to.meta.accessibleUnauthenticated) {
      return;
    }

    const authenticated = await authentication.isAuthenticated();
    if (!authenticated) {
      loginContextRepository.storeRedirectUrl(window.location.href);
      await authentication.login();
    }
  };
