import { InjectionKey } from 'vue';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Fan } from '@/staff/domain/fan/Fan';
import { FanId } from '@/staff/domain/fan/FanId';

export const fanRepositoryKey: InjectionKey<FanRepository> = Symbol();

export interface FanRepository {
  list(clubSlug: ClubSlug): Promise<Fan[]>;
  approveDueDiligence(clubSlug: ClubSlug, fanId: FanId): Promise<void>;
}
