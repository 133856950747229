include ../../../../common/primary/select/select.mixin.pug

PageVue
  .fp-vertical-spacing
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='update-giveaway.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('giveawayForm.updateTitle') }}
    .fp-vertical-spacing--line(v-if='!giveawayForm.isLoading()')
      GiveawayFormVue#update-giveaway-form(:form-value='giveawayForm.value()' :is-updating='true' :limited-update='limitedUpdate' @confirm='onConfirmUpdate' @form-valid='setFormValid')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='update-giveaway.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'giveawayForm.updateGiveaway\')' icon='edit' type='submit' form='update-giveaway-form' data-test='giveaway.form.submit' :disabled='!formValid')
