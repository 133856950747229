import { defineComponent, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';

export default defineComponent({
  name: 'MediaInputLine',

  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
    showActions: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isFirstMedia: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLastMedia: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  emits: ['move-up', 'move-down', 'delete-media'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const mediaNotFound = ref(false);
    const mediaLoaded = ref(false);
    const imageRef = ref<HTMLImageElement | null>(null);
    const videoRef = ref<HTMLVideoElement | null>(null);

    watch(
      () => [props.media],
      () => {
        mediaNotFound.value = false;
        if (props.media.type === MediaType.IMAGE) {
          mediaLoaded.value = imageRef.value?.complete ?? false;
        }
        if (props.media.type === MediaType.VIDEO) {
          mediaLoaded.value = (videoRef.value?.readyState ?? HTMLMediaElement.HAVE_NOTHING) >= HTMLMediaElement.HAVE_FUTURE_DATA;
        }
      }
    );

    const onNotFoundImage = () => {
      if (!mediaLoaded.value) {
        return;
      }

      mediaNotFound.value = true;
    };

    const onLoadedMedia = () => {
      mediaLoaded.value = true;
    };

    const moveUp = () => {
      emit('move-up');
    };

    const moveDown = () => {
      emit('move-down');
    };

    const deleteMedia = () => {
      emit('delete-media');
    };

    return {
      imageRef,
      videoRef,
      onNotFoundImage,
      mediaNotFound,
      onLoadedMedia,
      mediaLoaded,
      moveUp,
      moveDown,
      deleteMedia,
      t,
    };
  },
});
