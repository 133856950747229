const ANNOUNCEMENT_DEV_KEY = 'announcement.dev';

export interface AnnouncementUi {
  messageKey: string;
  showCloseAction: boolean;
  isWarning: boolean;
  messageArguments?: Record<string, any>;
  routeLink?: string;
  linkKey?: string;
}

export const createDevEnvironmentAnnouncementUi = (): AnnouncementUi => ({
  messageKey: `${ANNOUNCEMENT_DEV_KEY}.message`,
  showCloseAction: true,
  isWarning: false,
  routeLink: 'https://staff.fairplayer.co',
  linkKey: `${ANNOUNCEMENT_DEV_KEY}.link`,
});
