import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { Emitter, Handler } from 'mitt';
import { ClickBus } from '@/common/domain/click/ClickBus';
import { Clicked } from '@/common/domain/click/Clicked';

export class MittClickBus implements ClickBus {
  constructor(
    private emitter: Emitter<any>,
    window: Window
  ) {
    window.onclick = (event: MouseEvent) => this.click(event);
  }

  private click(event: MouseEvent): void {
    this.emitter.emit('click', event);
  }

  onClick(clicked: Clicked): Unsubscribe {
    const handler: Handler<MouseEvent> = event => clicked(event);
    this.emitter.on('click', handler);
    return () => this.emitter.off('click', handler);
  }
}
