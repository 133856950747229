import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';
import { Url } from '@/common/domain/Url';

export const LOGIN_REDIRECT_URL_KEY = 'login_redirect_url';

export class StorageLoginContextRepository implements LoginContextRepository {
  constructor(private storage: Storage) {}

  storeRedirectUrl(redirectUrl: Url): void {
    this.storage.setItem(LOGIN_REDIRECT_URL_KEY, redirectUrl);
  }

  retrieveRedirectUrl(): Url {
    return this.storage.getItem(LOGIN_REDIRECT_URL_KEY)!;
  }
}
