import { defineComponent, PropType } from 'vue';
import 'md-editor-v3/lib/style.css';
import { useI18n } from 'vue-i18n';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';

export default defineComponent({
  name: 'MarkdownTranslation',

  props: {
    translationKey: {
      type: String as PropType<string>,
      required: true,
    },
    translationVariables: {
      type: Object as PropType<Record<string, any>>,
      default: () => {},
    },
  },

  setup(props) {
    const { t } = useI18n();

    const text = renderMarkdown(t(props.translationKey, props.translationVariables));

    return {
      text,
    };
  },
});
