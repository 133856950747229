export class DateTimeInputUi {
  date: string;
  time: string;

  constructor(initialDate: Date) {
    this.date = this.toDateInput(initialDate);
    this.time = this.toTimeInput(initialDate);
  }

  static toDate(dateTimeInput: DateTimeInputUi): Date {
    return new Date(`${dateTimeInput.date}T${dateTimeInput.time}`);
  }

  private toDateInput(date: Date): string {
    const [day, month, year] = date
      .toLocaleDateString('fr-FR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .split('/');
    return `${year}-${month}-${day}`;
  }

  private toTimeInput(date: Date): string {
    return date
      .toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      .replace('24:00', '00:00');
  }
}
