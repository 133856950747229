import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import {
  BackendGiveawayExtraGiftToCreate,
  toBackendGiveawayExtraGiftToCreate,
} from '@/staff/secondary/club/giveaway/BackendGiveawayExtraGiftToCreate';

export interface BackendGiveawayClosureToCreate {
  coinsAmountByRightAnswer: number;
  extraGifts: BackendGiveawayExtraGiftToCreate[];
}

export const toBackendGiveawayClosureToCreate = (closureToCreate: GiveawayClosureToCreate): BackendGiveawayClosureToCreate => ({
  coinsAmountByRightAnswer: closureToCreate.coinsAmountByRightAnswer,
  extraGifts: closureToCreate.extraGifts.map(toBackendGiveawayExtraGiftToCreate),
});
