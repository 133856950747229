import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Orders } from '@/staff/domain/order/Orders';
import { InjectionKey } from 'vue';
import { Coin } from '@/common/domain/token/Coin';
import { OrderId } from '@/staff/domain/order/OrderId';

export const orderRepositoryKey: InjectionKey<OrderRepository> = Symbol();

export interface OrderRepository {
  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<Orders>;
  deliver(clubSlug: ClubSlug, orderId: OrderId): Promise<void>;
}
