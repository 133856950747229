import { Optional } from '@/common/domain/Optional';
import { StafferLanguageRepository } from '@/common/domain/StafferLanguageRepository';
import { Language } from '@/common/domain/Language';

const SELECTED_LANGUAGE_PREFIX = 'selected_language';

const SUPPORTED_LANGUAGES = ['en', 'fr'];

export class FairplayerStafferLanguageRepository implements StafferLanguageRepository {
  constructor(
    private storage: Storage,
    private window: Window
  ) {}

  storeLanguage(language: Language) {
    this.storage.setItem(SELECTED_LANGUAGE_PREFIX, language);
  }

  getCurrentLanguage(): Language {
    const forcedLanguage = import.meta.env.VITE_FORCED_LANGUAGE;
    if (forcedLanguage) {
      return forcedLanguage;
    }

    return this.getStoredLanguage().orElseGet(() => this.getNavigatorLanguage());
  }
  private getStoredLanguage(): Optional<string> {
    return Optional.ofUndefinable(this.storage.getItem(SELECTED_LANGUAGE_PREFIX));
  }

  private getNavigatorLanguage() {
    const navigatorLocale = this.window.navigator.language.split('-')[0];
    if (SUPPORTED_LANGUAGES.includes(navigatorLocale)) {
      return navigatorLocale;
    }

    return SUPPORTED_LANGUAGES[0];
  }
}
