import { Optional } from '@/common/domain/Optional';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayQuestionToUpdate } from '@/staff/domain/club/giveaway/GiveawayQuestionToUpdate';

export interface GiveawayToUpdate {
  id: GiveawayId;
  clubSlug: ClubSlug;
  url: Optional<string>;
  startDate: Optional<Date>;
  endDate: Optional<Date>;
  questions: Optional<GiveawayQuestionToUpdate[]>;
  partner: Optional<string>;
}

export type UpdatableGiveawayField = keyof Omit<GiveawayToUpdate, 'id' | 'clubSlug'>;

export const isGiveawayToUpdateEmpty = (giveawayToUpdate: GiveawayToUpdate) =>
  Object.keys(giveawayToUpdate)
    .filter(key => key !== 'id' && key !== 'clubSlug')
    .map(key => key as UpdatableGiveawayField)
    .map(key => giveawayToUpdate[key])
    .every(field => field.isEmpty());
